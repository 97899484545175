import React, { useState, useContext, useEffect } from 'react';
import { ActivityIndicator } from 'react-native-paper';
// import * as Linking from 'expo-linking';

import { AccountBackground } from 'components/AccountBackground';
import { AccountContainer } from 'components/AccountContainer';
import { AccountLink } from 'components/AccountLink';
import { AccountButton } from 'components/AccountButton';
import { AuthFields } from 'components/AuthFields';
import { AuthInput } from 'components/AuthInput';
import { AuthInputBorder } from 'components/AuthInputBorder';
import { ErrorContainer } from 'components/ErrorContainer';
import { Spacer } from 'components/Spacer/Spacer';
import { Text } from 'components/Text';

import { AuthenticationContext } from 'contexts/AuthenticationContext';
import { colors } from '../../theme/colors';

export const ActivateAccountScreen = ({ navigation, route }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { onRegister, isLoading, error } = useContext(AuthenticationContext);

  // const url = Linking.useURL();
  
  useEffect(() => {
    if (route?.params?.checkData?.invitationDetails) {
      const details = route?.params?.checkData?.invitationDetails;
      setFirstName(details?.firstName);
      setLastName(details?.lastName);
      setEmail(details?.email);
    }
  }, [route?.params?.checkData])

  const letMeInEnabled = () => {
    return (
      firstName !== '' &&
      lastName !== '' &&
      email !== '' &&
      password !== '' &&
      confirmPassword !== '' &&
      password === confirmPassword
    );
  };

  return (
    <AccountBackground>
      <AccountContainer>
        <Spacer size="heading" position="bottom">
          <Text variant="accountHeading">Activate Account</Text>
        </Spacer>
        <AuthFields>
          <AuthInputBorder>
            <AuthInput
              label="First Name"
              placeholder="First Name"
              value={firstName}
              textContentType="givenName"
              keyboardType="default"
              autoCapitalize="words"
              onChangeText={(u) => setFirstName(u)}
              autoFocus={true}
            />
          </AuthInputBorder>
          <AuthInputBorder>
            <AuthInput
              label="Last Name"
              placeholder="Last Name"
              value={lastName}
              textContentType="familyName"
              keyboardType="default"
              autoCapitalize="words"
              onChangeText={(u) => setLastName(u)}
            />
          </AuthInputBorder>
        </AuthFields>
        <AuthFields>
          <AuthInputBorder>
            <AuthInput
              label="Email"
              placeholder="Email"
              value={email}
              textContentType="emailAddress"
              keyboardType="email-address"
              autoCapitalize="none"
              onChangeText={(u) => setEmail(u)}
            />
          </AuthInputBorder>
          <AuthInputBorder>
            <AuthInput
              label="Password"
              placeholder="Password"
              value={password}
              textContentType="password"
              secureTextEntry
              autoCapitalize="none"
              onChangeText={(p) => setPassword(p)}
            />
          </AuthInputBorder>
          <AuthInput
            label="Confirm Password"
            placeholder="Confirm Password"
            value={confirmPassword}
            textContentType="password"
            secureTextEntry
            autoCapitalize="none"
            onChangeText={(p) => setConfirmPassword(p)}
          />
        </AuthFields>
        <AccountLink onPress={() => navigation.navigate('Login')}>
          Already have an account? Log in
        </AccountLink>
        <Spacer size="large">
          {!isLoading ? (
            <AccountButton
              title={'Let Me In'}
              isDisabled={!letMeInEnabled()}
              onPress={() =>
                onRegister(
                  firstName,
                  lastName,
                  email,
                  password,
                  confirmPassword,
                )
              }
            />
          ) : (
            <ActivityIndicator animating={true} color={colors.headfirst.green} />
          )}
        </Spacer>
        <ErrorContainer size="large">
          <Text variant="error">{error}</Text>
        </ErrorContainer>
      </AccountContainer>
    </AccountBackground>
  );
};
