import styled from 'styled-components/native';
import { DataTable } from 'react-native-paper';

export const CardInformationRow = styled.View`
  flex-direction: row;
`;

export const DataTableView = styled(DataTable)`
  background-color: white;
  flex: 1;
`;

export const RequiredText = styled.Text`
  color: red;
  font-size: 17px;
  font-weight: bold;
  padding-left: 8px;
`;

export const Heading = styled.Text`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
`;
// font-family: "SF Pro Display";

export const Text = styled.Text`
  color: #728596;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
`;
// font-family: "SF Pro Display";