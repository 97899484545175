import React, { useContext, useMemo } from 'react';
import styled from 'styled-components/native';
import { environment } from "../Env";

import { Linking, Text, useWindowDimensions, View } from 'react-native';

import { ReportsNavigator } from './ReportsNavigator';
import { ClubChatNavigator } from './ClubChatNavigator';
import { InsightsNavigator } from './InsightsNavigator';
import { DocumentsNavigator } from './DocumentsNavigator';
import { SettingsNavigator } from './SettingsNavigator';
import { PlayersNavigator } from './PlayersNavigator';
import { TeamsNavigator } from './TeamsNavigator';

import HomePNG from '../../assets/icons/home-full_blue.png';
const Home = styled.Image.attrs({
  source: HomePNG,
})``;
import ListPNG from '../../assets/icons/list_blue.png';
const List = styled.Image.attrs({
  source: ListPNG,
})``;
// import RequestsSVG from "../../assets/icons/requests.svg";
const RequestsSVG = styled.View``;
// import ClubsSVG from "../../assets/icons/requests.svg";
const ClubsSVG = styled.View``;
import DocumentPNG from '../../assets/document.png';
const Document = styled.Image.attrs({
  source: DocumentPNG,
})``;
// import UserSVG from "../../assets/icons/user-full.svg";
import UserPNG from '../../assets/icons/user-full_blue.png';
const User = styled.Image.attrs({
  source: UserPNG,
})``;
import SettingsPNG from '../../assets/icons/settings-full_blue.png';
const Settings = styled.Image.attrs({
  source: SettingsPNG,
})``;
import WarningPNG from '../../assets/icons/warning-full_blue.png';
const Warning = styled.Image.attrs({
  source: WarningPNG,
})``;
import StarPNG from '../../assets/icons/star-full_blue.png';
const Star = styled.Image.attrs({
  source: StarPNG,
})``;

const IconImage = styled.Image.attrs({
  resizeMode: 'contain'
})`
  width: 154px;
  height: 35px;
`;

const ClubView = styled.View``;
const ClubHeading = styled.Text.attrs({
  numberOfLines: 1,
})`
  color: #ebebeb;
  font-size: 20px;
  font-weight: 600;
  padding-horizontal: 16px;
  padding-top: 16px;
  padding-bottom: 8px;
`;

import { createDrawerNavigator, DrawerContentScrollView, DrawerItem, DrawerItemList } from '@react-navigation/drawer';

const Drawer = createDrawerNavigator();
import { colors } from '../theme/colors';
import { ProfileContext } from 'contexts/ProfileContext';
import { ClubUsersNavigator } from './ClubUsersNavigator';

const backgroundColor = (environment) => {
  return environment === 'production' ? colors.headfirst.green : colors.headfirst.red
}

const backgroundColorActive = (environment) => {
  return environment === 'production' ? colors.headfirst.greenBackgroundActive : colors.headfirst.redBackgroundActive
}

const backgroundColorDisabled = (environment) => {
  return environment === 'production' ? colors.headfirst.greenDisabled : colors.headfirst.redDisabled
}

const CustomDrawer = props => {
  const { navigation, state } = props;
  const currentScreen = state.history.at(-1)?.key;

  const headingText = useMemo(() => {
    const club = props?.currentClub?.club;
    return club?.nickname || club?.abbreviation || club?.name
  }, [props?.currentClub?.club]);

  return (
    <View style={{ flex: 1, paddingBottom: 8 }}>
      <View
        style={{
          flexDirection: "row",
          padding: 16,
          backgroundColor: backgroundColor(environment),
          alignItems: "center",
        }}>
        <IconImage
          source={require('../../assets/logos/primary_white_left.png')}
        />
      </View>
      <ClubHeading>{headingText}</ClubHeading>
      <DrawerContentScrollView
        {...props}
        contentContainerStyle={{ backgroundColor: backgroundColor(environment) }}>
        <View style={{ paddingVertical: 0 }}>
          <DrawerItemList {...props} />
        </View>
      </DrawerContentScrollView>
      <DrawerItem label={() => <Text style={{ color: backgroundColorDisabled(environment) }}>User Guide</Text>}
          onPress={() => Linking.openURL('https://headfirstapp.com/club-portal-user-guide')}
        />
      <DrawerItem label={() => <Text style={{ color: currentScreen?.includes("Profile") ? colors.pure.white : backgroundColorDisabled(environment) }}>Profile</Text>}
        style={{ backgroundColor: currentScreen?.includes("Profile") ? backgroundColorActive(environment) : ''}}
        onPress={() => navigation.navigate("Profile")}
      />
    </View>
  );
};

export const AppDrawerNavigator = () => {
  const { currentClub, currentFeatures, profile } = useContext(ProfileContext);

  const canViewInsights = useMemo(() => {
    return (currentClub?.role === 'club_admin' && currentFeatures?.includes('insights')) || profile?.email?.endsWith('simpler.software')
  }, [profile]);

  const canViewTrainers = useMemo(() => {
    return currentClub?.role === 'club_admin';
  }, [currentClub]);

  const canViewClubUsers = useMemo(() => {
    return currentClub?.role === 'club_admin';
  }, [currentClub]);

  const canViewTeams = useMemo(() => {
    return (currentClub?.role === 'club_admin' && currentFeatures?.includes('manage_teams')) || false;
  }, [currentClub, currentFeatures]);

  const clubChatEnabled = useMemo(() => {
    return currentFeatures?.includes('club_chat') || false
  }, [currentFeatures]);

  const dimensions = useWindowDimensions();
  return (
    <Drawer.Navigator screenOptions={{
      drawerType: dimensions.width >= 768 ? 'permanent' : 'slide',
      headerShown: dimensions.width >= 768 ? false : true,
      drawerStyle: {
        backgroundColor: backgroundColor(environment),
        width: 200,
      },
      drawerActiveTintColor: colors.pure.white,
      drawerInactiveTintColor: backgroundColorDisabled(environment),
    }}
      drawerContent={props => <CustomDrawer {...props} currentClub={currentClub} />}
    >
      <Drawer.Screen name="Reports" component={ReportsNavigator} />
      <Drawer.Screen name="Documents" component={DocumentsNavigator} />
      <Drawer.Screen name="Players" component={PlayersNavigator} />
      {canViewTeams && (
        <Drawer.Screen name="Teams" component={TeamsNavigator} />
      )}
      {canViewClubUsers && (
        <Drawer.Screen name="Users" component={ClubUsersNavigator} />
      )}
      {clubChatEnabled && (
        <Drawer.Screen name="Chat" component={ClubChatNavigator} />
      )}
      {canViewInsights && (
        <Drawer.Screen name="Insights" component={InsightsNavigator} />
      )}
      <Drawer.Screen name="Profile" component={SettingsNavigator} options={{ drawerItemStyle: { display: 'none' } }} />
    </Drawer.Navigator>
  );
};
