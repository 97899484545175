import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/native';
import * as DocumentPicker from 'expo-document-picker';

import { Alert } from 'components/Alert';
import { Background } from 'components/Background';
import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { BarSwitcher } from 'components/BarSwitcher';
import { ButtonBlue } from 'components/ButtonBlue';
import { ButtonWhite } from 'components/ButtonWhite';
import { CardInformation } from 'components/CardInformation';
import { Centered } from 'components/Centered';
import { Confirm } from 'components/Confirm';
import { FormFieldText } from 'components/FormFieldText';
import { Loading } from 'components/Loading';
import { VerticalScroll } from 'components/VerticalScroll';

import { ProfileContext } from 'contexts/ProfileContext';
import { useCreateDocument, useUpdateDocument, useDeleteDocument } from 'hooks/useDocument';

import { openLink } from 'utils/links';

const CardInformationRow = styled.View`
  flex-direction: row;
`;

export const DocumentDetailsScreen = ({ route, navigation }) => {
  const { currentClub } = useContext(ProfileContext);
  const clubId = useMemo(() => { return currentClub?.club?.id }, [currentClub]);

  const { mutate: createDocument, isPending: isCreating, isSuccess: hasCreated, isError: isErrorCreate, error: createError } = useCreateDocument({ clubId });
  const { mutate: updateDocument, isPending: isUpdating, isSuccess: hasUpdated, isError: isErrorUpdate, error: updateError } = useUpdateDocument({ clubId });
  const { mutate: deleteDocument, isPending: isDeleting, isSuccess: hasDeleted, isError: isDeletionError, error: deletionError } = useDeleteDocument({ clubId });

  const [recordDeleted, setRecordDeleted] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const [id, setId] = useState(null);
  const [date, setDate] = useState('');
  const [document, setDocument] = useState({});

  const [file, setFile] = useState({});
  const [filename, setFilename] = useState('');
  const [weblink, setWeblink] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const isClubAdmin = useMemo(() => {
    return currentClub?.role === 'club_admin';
  }, [currentClub]);

  useEffect(() => {
    if (route.params?.document) {
      setDocument(route.params?.document);
      setId(route.params?.document?.id);
      setDate(route.params?.document?.updatedAtShort);
      setFilename(route.params?.document?.file?.filename);
      setWeblink(route.params?.document?.weblink);
      setTitle(route.params?.document?.title || '');
      setDescription(route.params?.document?.description || '');
      setCurrentTab(route.params?.document?.weblink ? 1 : 0)
    }
  }, [route.params?.document]);

  const pickFile = async () => {
    let result = await DocumentPicker.getDocumentAsync({});
    if (!result.canceled && result.assets.length == 1) {
      setFile(result.assets[0]);
      setFilename(result.assets[0].name);
      if (!title) {
        setTitle(result.assets[0].name);
      }
    }
  };

  const canSave = useMemo(() => {
    return !(file == {}) && title !== '';
  })

  const saveDocument = () => {
    const document = { id, title, description, file, weblink };

    if (id) {
      updateDocument({ clubId, document });
    } else {
      createDocument({ clubId, document });
    }
  }

  const deleteRecord = () => {
    Confirm('Confirm Delete', `Delete ${title}`, () => {
      deleteDocument({ clubId, documentId: id });
      setRecordDeleted(true);
    }, () => { });
  };

  useEffect(() => {
    if (hasCreated || hasUpdated || hasDeleted) {
      navigation.navigate('DocumentsScreen');
    }
  }, [hasCreated, hasUpdated, hasDeleted]);

  useEffect(() => {
    if (createError) {
      Alert('Something went wrong', createError?.toString(), [
        { text: 'OK', onPress: null },
      ]);
    }
    if (updateError) {
      Alert('Something went wrong', updateError?.toString(), [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [isErrorCreate, isErrorUpdate]);

  return (
    <Background>
      {isClubAdmin ? (
        <BarHeaderSmall
          title={id ? 'Update Document' : 'New Document'}
          leftButtonTitle="Back"
          onLeftPress={() => {
            if (canSave) { }
            navigation.navigate('DocumentsScreen', { updateTime: new Date().getTime() });
          }}
          rightButtonTitle={'Delete'}
          onRightPress={deleteRecord}
        />
      ) : (
        <BarHeaderSmall
          title={id ? 'Update Document' : 'New Document'}
          leftButtonTitle="Back"
          onLeftPress={() => {
            if (canSave) { }
            navigation.navigate('DocumentsScreen', { updateTime: new Date().getTime() });
          }}
        />
      )}
      <VerticalScroll>
        <Centered>
            {!id && (
              <BarSwitcher
                items={['File', 'Weblink']}
                tab={currentTab}
                onSelected={(item) => {
                  setCurrentTab(['File', 'Weblink'].findIndex((i) => i === item));
                }}
              />
            )}
            {currentTab === 0 && (
              <>
                <CardInformationRow>
                  <CardInformation title="Last Updated">{date}</CardInformation>
                  <CardInformation title="Filename">{filename}</CardInformation>
                </CardInformationRow>
                <ButtonWhite label="Pick a file" onPress={() => pickFile()} />
                <FormFieldText label="Title" value={title} onChangeText={setTitle} />
                <FormFieldText
                  label="Description"
                  value={description}
                  onChangeText={setDescription}
                />
                <ButtonBlue
                  label={id ? 'Update' : 'Upload'}
                  isDisabled={!canSave}
                  onPress={saveDocument}
                />
                <ButtonWhite
                  label="View"
                  onPress={() => {
                    openLink(document?.file?.url);
                  }}
                />
              </>
            )}
            {currentTab === 1 && (
              <>
                <FormFieldText label="Title" value={title} onChangeText={setTitle} />
                <FormFieldText
                  label="Description"
                  value={description}
                  onChangeText={setDescription}
                />
                <FormFieldText
                  label="Weblink"
                  value={weblink}
                  onChangeText={setWeblink}
                  keyboardType="url"
                  autoCapitalize="none"
                />
                <ButtonBlue
                  label={id ? 'Update' : 'Save'}
                  isDisabled={!canSave}
                  onPress={saveDocument}
                />
                <ButtonWhite
                  label="Open Link"
                  onPress={() => {
                    openLink(document?.weblink);
                  }}
                />
              </>
            )}
        </Centered>
      </VerticalScroll>
      <Loading isLoading={isCreating || isUpdating} />
    </Background>
  );
};
