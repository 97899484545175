import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Alert, View } from 'react-native';
import { DataTable } from 'react-native-paper';
import DropDownPicker from 'react-native-dropdown-picker';

import { Background } from 'components/Background';
import { BarHeaderLarge } from 'components/BarHeaderLarge';
import { BarMenu } from 'components/BarMenu';
import { Loading } from 'components/Loading';
import { PlayerCard } from 'components/PlayerCard';
import { SearchTextInput } from 'components/SearchTextInput';
import { sizeClasses, SizeClassView } from 'components/SizeClassView';
import {
  Container,
  RefreshText,
  BarButton,
  DataTableView,
  PlayersList,
  RefreshControl,
  PlusIcon,
  AddButton
} from './styles';
import { parseDate } from 'utils/Dates';
import { differenceInDays, startOfDay } from 'date-fns';

import { useQueryClient } from '@tanstack/react-query';
import { ProfileContext } from 'contexts/ProfileContext';
import { refreshPlayers, useGetPlayers } from 'hooks/usePlayer';

import { ChangeTeam } from '../ChangeTeamScreen/ChangeTeam';

export const PlayersScreen = ({ navigation, route }) => {
  const queryClient = useQueryClient()
  const { currentClub, currentTeam } = useContext(ProfileContext);
  const clubId = useMemo(() => currentClub?.clubId, [currentClub]);
  const leagueId = useMemo(() => {
    if (currentClub) {
      return currentClub?.club.affiliations[0]?.leagueId;
    }
  }, [currentClub]);

  const { data: players, isLoading, isError, error } = useGetPlayers({ clubId });

  const [playerSearch, setPlayerSearch] = useState('');

  const [statusDropDownOpen, setStatusDropDownOpen] = useState(false);
  const [statusDropDownValue, setStatusDropDownValue] = useState(null);
  const [statusItems, setStatusItems] = useState([
    { label: 'All Players', value: null },
    { label: 'Not cleared to play', value: 'Not cleared to play' },
  ]);
  const [concussionDropDownOpen, setConcussionDropDownOpen] = useState(false);
  const [concussionDropDownValue, setConcussionDropDownValue] = useState(null);
  const [teamDropDownOpen, setTeamDropDownOpen] = useState(false);
  const [teamDropDownValue, setTeamDropDownValue] = useState(null);

  // Season
  const [activeDropDown, setActiveDropDown] = useState('');

  useEffect(() => {
    if (!!isError) {
      Alert.alert('Something went wrong', error?.toString(), [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [isError]);

  const clubPlayers = useMemo(() => {
    if (!players) return [];

    return players
  }, [players]);

  const displayTeam = (item) => {
    if (item?.teamPlayers?.length === 0) return '';

    var teams = item.teamPlayers.map((teamPlayer) => teamPlayer.team);

    return teams
      .map((team) => team.name)
      .join(', ')
  }

  const teamItems = useMemo(() => {
    if (!clubPlayers) return [];

    var p = clubPlayers;
    if (currentClub?.role === 'trainer') {
      p = p
        .filter(player => player.teamPlayers
          .some(teamPlayer => currentClub?.teams.map((team) => team.id).includes(teamPlayer.team.id)))
    }

    const teams = [...new Set(p?.map(player => player.teamPlayers.map(teamPlayer => teamPlayer.team.name)).flat().sort())];
    const items = teams?.map(team => ({ label: team, value: team }))
    return [{ label: 'All Teams', value: null }, ...items]
  }, [clubPlayers]);

  const concussionItems = useMemo(() => {
    const concussions = [...new Set(clubPlayers?.map(player => player.totalConcussions))].sort()
    const items = concussions?.map(count => ({ label: `${count}`, value: count }))
    return [
      { label: 'All', value: null },
      { label: 'Confirmed concussion', value: 'confirmed' },
    ]
  }, [clubPlayers]);

  const filteredPlayers = useMemo(() => {
    var p = clubPlayers;
    if (currentClub?.role === 'trainer') {
      p = p
        .filter(player => player.teamPlayers
          .some(teamPlayer => currentClub?.teams.map((team) => team.id).includes(teamPlayer.team.id)))
    }
    if (playerSearch) {
      p = p.filter(record => {
        const teamPlayerText = record.teamPlayers.map((teamPlayer) => teamPlayer?.team?.name);
        const concussionText = record?.totalConcussions > 0 && 'concussion';
        const lastStatus = record?.lastStatus;
        const searchText = `${record.firstName} ${record.lastName} ${record.additionalInformation} ${teamPlayerText} ${concussionText} ${lastStatus}`.toLowerCase()
        return searchText.includes(playerSearch.toLowerCase())
      })
    }
    if (teamDropDownValue) {
      p = p
        .filter(player => player.teamPlayers
          .some(teamPlayer => teamPlayer.team.name === teamDropDownValue))
    }
    if (!!currentTeam) {
      p = p
        .filter(player => player.teamPlayers
          .some(teamPlayer => teamPlayer.team.id === currentTeam.id))
    }
    if (concussionDropDownValue === 'confirmed') {
      p = p.filter(player => player?.totalConcussions > 0)
    }
    if (statusDropDownValue != null) {
      p = p.filter(player => player?.lastStatus === statusDropDownValue)
    }
    return p;
  }, [clubPlayers, currentTeam, teamDropDownValue, concussionDropDownValue, statusDropDownValue, playerSearch, route.params?.updateTime]);

  const canImportPlayers = useMemo(() => {
    return currentClub?.role === 'club_admin';
  }, [currentClub]);

  const returnToPlay = (returnToPlayOn) => {
    if (!returnToPlayOn) { return }
    const date1 = parseDate(returnToPlayOn);
    if (date1 == 'Invalid Date') {
      return
    }
    const rtp = parseDate(returnToPlayOn);
    const today = startOfDay(new Date());
    const dayDiff = differenceInDays(rtp, today);
    if (dayDiff > 1) {
      return `${dayDiff} days`;
    } else if (dayDiff == 1) {
      return `${dayDiff} day`;
    }else if (dayDiff == 0) {
      return 'Today';
    }
  }

  const returnToPlayTitle = (returnToPlayOn) => {
    const value = returnToPlay(returnToPlayOn);
    if (!value) { return '' }
    if (value == 'Today') {
      return 'Returns to play';
    } else {
      return 'Returns to play in';
    }
  };


  const navToImportPlayers = () => navigation.navigate('ImportPlayersScreen')
  const navToImportPlayerUpload = () => navigation.navigate('ImportPlayerUploadScreen')
  const navToPlayerDetail = (playerId) => navigation.navigate('PlayerUpdateScreen', { playerId })

  return (
    <Background>
      <BarHeaderLarge title="Players">
        <SizeClassView size={sizeClasses.regular}>
          {canImportPlayers && (
            <>
              <BarButton onPress={() => navToImportPlayerUpload()}>
                <RefreshText>Import</RefreshText>
              </BarButton>
              <BarButton onPress={() => navToImportPlayers()}>
                <RefreshText>Import History</RefreshText>
              </BarButton>
            </>
          )}
          <BarButton onPress={() => refreshPlayers({ queryClient, clubId })}>
            <RefreshText>Refresh</RefreshText>
          </BarButton>
        </SizeClassView>
        <BarButton onPress={() => navigation.navigate('PlayerCreateScreen')}>
          <PlusIcon />
        </BarButton>
      </BarHeaderLarge>
      <SizeClassView size={sizeClasses.compact}>
        <ChangeTeam navigation={navigation} />
          <PlayersList
            data={filteredPlayers}
            renderItem={({ item }) => (
              <AddButton onPress={() => {navToPlayerDetail(item.id);}}>
                <PlayerCard
                  title={`${item?.firstName} ${item?.lastName}`}
                  // date={"Return to Play in 6 days"}
                  date={returnToPlay(item?.returnToPlayOn)}
                  dateTitle={returnToPlayTitle(item?.returnToPlayOn)}
                  isClosed={false}
                  backgroundColor={'ffeeee'}
                >
                  {item?.additionalInformation}
                </PlayerCard>
              </AddButton>
            )}
            keyExtractor={(item) => item.id}
            refreshControl={
              <RefreshControl
                refreshing={isLoading}
                onRefresh={() => {
                  refreshPlayers({ queryClient, clubId });
                }}
              />
            }
          />
      </SizeClassView>
      <SizeClassView size={sizeClasses.regular}>
        <BarMenu>
          <View style={{ width: '15%', marginHorizontal: 4 }}>
            <DropDownPicker
              placeholder="All Players"
              style={{
                borderWidth: 1,
                borderColor: '#d5d5d9',
                minHeight: 40,
              }}
              dropDownContainerStyle={{
                borderWidth: 1,
                borderColor: '#d5d5d9',
              }}
              items={statusItems}
              open={statusDropDownOpen}
              setOpen={setStatusDropDownOpen}
              value={statusDropDownValue}
              setValue={setStatusDropDownValue}
            />
          </View>
          <View style={{ width: '15%', marginHorizontal: 4 }}>
            <DropDownPicker
              placeholder="All"
              style={{
                borderWidth: 1,
                borderColor: '#d5d5d9',
                minHeight: 40,
              }}
              dropDownContainerStyle={{
                borderWidth: 1,
                borderColor: '#d5d5d9',
              }}
              items={concussionItems}
              open={concussionDropDownOpen}
              setOpen={setConcussionDropDownOpen}
              value={concussionDropDownValue}
              setValue={setConcussionDropDownValue}
            />
          </View>
          <View style={{ width: '15%', marginHorizontal: 4 }}>
            <DropDownPicker
              placeholder="All Teams"
              style={{
                borderWidth: 1,
                borderColor: '#d5d5d9',
                minHeight: 40,
              }}
              dropDownContainerStyle={{
                borderWidth: 1,
                borderColor: '#d5d5d9',
              }}
              items={teamItems}
              open={teamDropDownOpen}
              setOpen={setTeamDropDownOpen}
              value={teamDropDownValue}
              setValue={setTeamDropDownValue}
            />
          </View>
          <View style={{ flex: 1 }}></View>
          <View style={{ width: '30%' }}>
            <SearchTextInput
              placeholder="Search"
              value={playerSearch}
              onChangeText={(text) => {
                setPlayerSearch(text);
              }}
              autoFocus={true}
            />
          </View>
        </BarMenu>
        <Container style={{ zIndex: -10 }}>
          <DataTableView>
            <DataTable.Header>
              <DataTable.Title style={{ flex: 2 }}>Name</DataTable.Title>
              <DataTable.Title style={{ flex: 2 }}>Additional Information</DataTable.Title>
              <SizeClassView size={sizeClasses.regular}>
                <DataTable.Title style={{ flex: 2 }}>Team</DataTable.Title>
                <DataTable.Title style={{ flex: 1 }} textStyle={{ flex: 1, textAlign: 'center' }}>Concussions</DataTable.Title>
                <DataTable.Title style={{ flex: 1 }}>Status</DataTable.Title>
                <DataTable.Title style={{ flex: 1 }}>Return to Play</DataTable.Title>
              </SizeClassView>
            </DataTable.Header>
            <PlayersList
              data={filteredPlayers}
              renderItem={({ item }) => (
                <DataTable.Row
                  key={item.id}
                  onPress={() => {
                    navToPlayerDetail(item.id);
                  }}>
                  <DataTable.Cell style={{ flex: 2 }}>{item?.firstName} {item?.lastName}</DataTable.Cell>
                  <DataTable.Cell style={{ flex: 2 }}>{item?.additionalInformation}</DataTable.Cell>
                  <SizeClassView size={sizeClasses.regular}>
                    <DataTable.Cell style={{ flex: 2 }}>
                      {displayTeam(item)}
                    </DataTable.Cell>
                    <DataTable.Cell style={{ flex: 1 }} textStyle={{ flex: 1, textAlign: 'center' }}>{item?.totalConcussions}</DataTable.Cell>
                    <DataTable.Cell style={{ flex: 1 }}>{item?.lastStatus}</DataTable.Cell>
                    <DataTable.Cell style={{ flex: 1 }}>{returnToPlay(item?.returnToPlayOn)}</DataTable.Cell>
                  </SizeClassView>
                </DataTable.Row>
              )}
              keyExtractor={(item) => item.id}
              refreshControl={
                <RefreshControl refreshing={isLoading} onRefresh={() => refreshPlayers({ queryClient, clubId })} />
              }
            />
          </DataTableView>
        </Container>
      </SizeClassView>
      <Loading isLoading={isLoading} />
    </Background>
  );
};
