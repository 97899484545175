import React, { useContext, useLayoutEffect, useMemo, useState, } from 'react';

import { AvoidingView } from 'components/AvoidingView';
import { Background } from 'components/Background';
import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { Centered } from 'components/Centered';
import { DropDownList } from 'components/DropDownList';
import { FormTableSelect } from 'components/FormTableSelect';
import { FormFieldText } from 'components/FormFieldText';
import { VerticalScroll } from 'components/VerticalScroll';
import {
  Description,
  FilterContainer,
  OtherContainer
} from './styles';

import { ProfileContext } from 'contexts/ProfileContext';
import { useGetPlayers } from 'hooks/usePlayer';
import { useGetTeams } from 'hooks/useTeam';

export const PlayerSelectionScreen = ({ navigation, route }) => {
  const { currentClub, currentTeam } = useContext(ProfileContext);
  const clubId = useMemo(() => currentClub?.clubId, [currentClub]);
  const { data: players } = useGetPlayers({ clubId });
  const { data: teams } = useGetTeams({ clubId });

  const [teamFilter, setTeamFilter] = useState('All my teams');

  const [playerSearch, setPlayerSearch] = useState('');
  const [title, setTitle] = useState('Select');
  const [header, setHeader] = useState('Select');
  const [description, setDescription] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);

  useLayoutEffect(() => {
    if (route.params?.title) {
      setTitle(route.params?.title);
    }
    if (route.params?.header) {
      setHeader(route.params?.header);
    }
    if (route.params?.description) {
      setDescription(route.params?.description);
    }
    if (route.params?.items) {
    }
    if (route.params?.selectedItems) {
      setSelectedItems(route.params?.selectedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params]);

  const filteredTeams = useMemo(() => {
    if (!teams) { return [] }

    var items = teams?.map(team => ({ id: team.id, name: team.name }))
    if (currentClub?.role === 'trainer') {
      items = items
        .filter(item => currentClub?.teams.map((team) => team.id).includes(item.id))
    }

    return [{ id: 'All my teams', name: 'All my teams' }, ...items]
  }, [teams]);

  const filteredPlayers = useMemo(() => {
    var p = players;
    if (!players) return []

    if (!!playerSearch) {
      p = p
        .filter(player => (player.firstName + ' ' + player.lastName).toLowerCase().includes(playerSearch.toLowerCase()))
    } else {
      if (currentClub?.role === 'trainer') {
        p = p
          .filter(player => player.teamPlayers
            .some(teamPlayer => currentClub?.teams.map((team) => team.id).includes(teamPlayer.team.id)))
      }
      if (teamFilter != 'All my teams') {
        p = p.filter(player => player.teamPlayers.map((teamPlayer) => teamPlayer.team.id).includes(teamFilter))
      }
    }
    p = p
      .map((player) => {
        return {
          id: player.id,
          name: player.firstName + ' ' + player.lastName,
          teamPlayers: player.teamPlayers,
        };
      })
      .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : 0);
    return p;
  }, [currentClub, teamFilter, currentTeam, players, playerSearch]);

  const onSave = (item) => {
    navigation.navigate('CreateReportScreen', {
      items: item,
    });
  };

  return (
    <>
      <Background>
        {playerSearch ? (
          <BarHeaderSmall
            title={title}
            leftButtonTitle="Cancel"
            onLeftPress={() => {
              navigation.navigate('CreateReportScreen', {});
            }}
          />
        ) : (
          <BarHeaderSmall
            title={title}
            leftButtonTitle="Cancel"
            onLeftPress={() => {
              navigation.navigate('CreateReportScreen', {});
            }}
          />
        )}
        <AvoidingView>
          <Centered>
            <OtherContainer>
              <FormFieldText
                label="Player Search"
                placeholder="Search"
                value={playerSearch}
                onChangeText={(text) => {
                  setPlayerSearch(text);
                }}
                autoFocus={true}
              />
            </OtherContainer>
          </Centered>
          <VerticalScroll>
            <Centered>
              {filteredTeams && (
                <FilterContainer>
                  <DropDownList
                    items={filteredTeams}
                    value={teamFilter}
                    onChangeValue={setTeamFilter}
                  />
                </FilterContainer>
              )}
              <FormTableSelect
                label={header}
                values={filteredPlayers.map((player) => ({
                  key: player.id,
                  value: player.name,
                }))}
                initialKeys={[selectedItems.id]}
                multiselect={false}
                onSelected={(selected) => {
                  const id = selected[0];
                  if (id) {
                    const item = filteredPlayers.find((i) => i.id === id);
                    setSelectedItems(item.name);
                    setTimeout(() => {
                      onSave(item);
                    }, 50);
                  }
                }}
              />
              <Description>{description}</Description>
            </Centered>
          </VerticalScroll>
        </AvoidingView>
      </Background>
    </>
  );
};
