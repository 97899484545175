import React from 'react';
import { Platform } from 'react-native';

import { Text } from 'components/Text';
import {
  Content,
  Header,
  IconImage,
  Footer
} from './styles'
import { AccountBackgroundWeb } from './AccountBackgroundWeb';
import { AccountBackgroundMobile } from './AccountBackgroundMobile';

const isWeb = Platform.OS === 'web';

const AccountHeader = () => {
  return (
    <Header>
      <IconImage
        source={require('../../../assets/logos/primary_white_above.png')}
      />
    </Header>
  )
}

const AccountFooter = () => {
  return (
    <Footer>
      <Text variant="trademark">© 2022-2025 Simpler Software Pty Ltd. All Rights Reserved.</Text>
    </Footer>
  )
}

export const AccountBackground = ({
  children,
  includeHeader = true,
  includeFooter = true,
}) => {
  return (
    <>
      {isWeb ? (
        <AccountBackgroundWeb>
          <Content>
            {includeHeader && (<AccountHeader />)}
            {children}
            {includeFooter  &&  (<AccountFooter />)}
          </Content>
        </AccountBackgroundWeb>
      ) : (
        <AccountBackgroundMobile>
          <Content>
            {includeHeader && (<AccountHeader />)}
            {children}
            {includeFooter  &&  (<AccountFooter />)}
          </Content>
        </AccountBackgroundMobile>
      )}
    </>
  );
};
