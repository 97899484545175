import React from 'react';

import {
  Container,
  ClosedContainer,
  Background,
  Content,
  TitleLine,
  Title,
  Date,
  DateTitle,
  ClosedDate,
  ClosedTitle,
  Message,
  ClosedMessage,
  Icon,
  DeleteIcon,
  DeleteButton,
  EditIcon,
  EditButton,
} from './styles'
import { View } from 'react-native';

export const PlayerCard = ({ title, date, dateTitle, isEditing, isClosed, onDelete, onUpdate, children }) => {
  return (
    <>
      {isClosed ? (
        <ClosedContainer>
          <Background>
            <Content>
              <TitleLine>
                <Title>{title}</Title>
                <Date>{date}</Date>
                <Icon />
              </TitleLine>
              <TitleLine>
                <Message>{children}</Message>
                <DateTitle>{dateTitle}</DateTitle>
              </TitleLine>
            </Content>
          </Background>
        </ClosedContainer>
      ) : (
        <Container>
          <Background>
            {isEditing && (
              <DeleteButton onPress={() => {
                if (onDelete) {
                  onDelete();
                }
              }}>
                <DeleteIcon />
              </DeleteButton>
            )}
            <Content>
              <TitleLine>
                <Title>{title}</Title>
                <Date>{date}</Date>
                <Icon />
              </TitleLine>
              <TitleLine>
                <Message>{children}</Message>
                <DateTitle>{dateTitle}</DateTitle>
              </TitleLine>
            </Content>
            {isEditing && (
              <EditButton onPress={() => {
                if (onUpdate) {
                  onUpdate();
                }
              }}>
                <EditIcon />
              </EditButton>
            )}
          </Background>
        </Container>
      )}
    </>
  );
};
