import React from 'react';

import {
  Container,
  TitleContainer,
  LabelText,
  RequiredText,
  InputContainer,
  Input,
} from './styles'
import { colors } from 'theme/colors';
import { DropDownListWeb } from '../DropDownListWeb';

export const FormFieldDropDownWeb = ({
  label,
  value,
  options,
  required = false,
  onChangeText,
}) => {
  return (
    <Container>
      <TitleContainer>
        <LabelText>{label}</LabelText>
        {required && <RequiredText>(Required)</RequiredText>}
      </TitleContainer>
      <DropDownListWeb
        items={options}
        value={value}
        onChangeValue={(value) => {
          const item = options.find((element) => (element.id == value))?.id;
          onChangeText(item)
        }}
      />
    </Container>
  );
};
