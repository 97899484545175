import styled from 'styled-components/native';
import DateTimePicker from '@react-native-community/datetimepicker';

export const Container = styled.View`
  width: 100%;
  margin-vertical: 0px;
`;

export const HeaderContainer = styled.View`
  flex-direction: row;
  margin-top: 16px;
`;

export const HeaderText = styled.Text`
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
  padding-horizontal: 16px;
  padding-bottom: 8px;
`;
// font-family: "SF Pro Display";

export const RequiredText = styled.Text`
  color: ${(props) => props.theme.colors.error.red};
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
`;
// font-family: "SF Pro Display";
export const LabelText = styled.Text`
  color: #000000;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 12px;
  text-transform: uppercase;
  padding-horizontal: 16px;
  padding-bottom: 8px;
`;
// font-family: "SF Pro Display";

// Web
export const InputContainer = styled.View`
  height: 56px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.pure.white};
  border-top-color: ${(props) => props.theme.colors.border};
  border-top-width: 0.5px;
  border-bottom-color: ${(props) => props.theme.colors.border};
  border-bottom-width: 0.5px;
  justify-content: center;
`;

// iOS/Android
export const DatePicker = styled(DateTimePicker).attrs({
  mode: 'date',
  is24Hour: true,
  display: 'default',
  themeVariant: 'light',
})``;

// Android
export const Pressable = styled.Pressable`
  padding: 6px;
  border-radius: 4px;
  align-items: center;
`;

export const DateText = styled.Text.attrs({
  numberOfLines: 1,
  ellipsizeMode: 'tail',
})`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
`;
// font-family: "SF Pro Display";

export const DateTextIOS = styled.Text`
  background-color: ${(props) => props.theme.colors.dateBackgroundGrey};
  padding: 8px;
  font-size: 16px;
  letter-spacing: 0;
`;
// font-family: "SF Pro Display";
