import React from 'react';

import {
  Container,
  HeaderContainer,
  HeaderText,
  RequiredText,
} from './styles'

export const FormFieldHeading = ({
  label,
  required,
}) => {
  return (
    <Container>
      <HeaderContainer>
        <HeaderText>{label}</HeaderText>
        {label && required && <RequiredText>(Required)</RequiredText>}
      </HeaderContainer>
    </Container>
  );
};
