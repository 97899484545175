import React, { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Alert, Platform } from 'react-native';
import styled from 'styled-components/native';

import { Background } from 'components/Background';
import { BarHeaderLarge } from 'components/BarHeaderLarge';
import { ButtonBlue } from 'components/ButtonBlue';
import { ButtonWhite } from 'components/ButtonWhite';
import { Centered } from 'components/Centered';
import { Footer } from 'components/Footer';
import { FormFieldHeading } from 'components/FormFieldHeading';
import { FormFieldMinimalTextDate } from 'components/FormFieldMinimalTextDate';
import { FormFieldText } from 'components/FormFieldText';
import { FormFieldTextMulti } from 'components/FormFieldTextMulti';
import { FormTableSelect } from 'components/FormTableSelect';
import { Loading } from 'components/Loading';
import { SizeClassView, sizeClasses } from 'components/SizeClassView';
import { Spacer } from 'components/Spacer';
import { VerticalScroll } from 'components/VerticalScroll';
import { openLink } from 'utils/links';
import { BarButton, LogoutText } from './styles';

import { useQueryClient } from '@tanstack/react-query';
import { AuthenticationContext } from 'contexts/AuthenticationContext';
import { ProfileContext } from 'contexts/ProfileContext';
import { refreshMe, useGetMe } from 'hooks/useMe';
import { useUpdateMeClubPermission } from 'hooks/useMeClubPermission';

export const SettingsScreen = ({ navigation }) => {
  const queryClient = useQueryClient();
  const { onLogout, onDeleteAccount } = useContext(AuthenticationContext);
  const { profile, currentClub, setCurrentClub, error } = useContext(ProfileContext);
  const { data: me, isLoading, isError, error: meError } = useGetMe();

  const clubPermissionId = useMemo(() => { return currentClub?.id });
  const clubPermission = useMemo(() => {
    const clubPermission = me?.clubPermissions.find(
      (clubPermission) => (clubPermission.id === currentClub?.id),
    );
    return clubPermission;
  }, [me, currentClub])
  const { mutate: updateClubPermission, isPending, isSuccess, isError: isErrorClubPermission, error: errorClubPermission } = useUpdateMeClubPermission({ clubPermissionId });

  const [certificateFirstAidExpiresOn, setCertificateFirstAidExpiresOn] = useState('');
  const [certificateCprExpiresOn, setCertificateCprExpiresOn] = useState('');
  const [certificateErcExpiresOn, setCertificateErcExpiresOn] = useState('');
  const [certificateLevel1SportsTrainerExpiresOn, setCertificateLevel1SportsTrainerExpiresOn] = useState('');

  useLayoutEffect(() => {
    if (currentClub) {
      setCertificateFirstAidExpiresOn(currentClub?.certificateFirstAidExpiresOn ?? '');
      setCertificateCprExpiresOn(currentClub?.certificateCprExpiresOn ?? '');
      setCertificateErcExpiresOn(currentClub?.certificateErcExpiresOn ?? '');
      setCertificateLevel1SportsTrainerExpiresOn(currentClub?.certificateLevel1SportsTrainerExpiresOn ?? '');
    }
  }, [currentClub])

  const canSave = useMemo(() => {
    if (!clubPermission) return false;
    var somethingChanged = false;

    if (certificateFirstAidExpiresOn && certificateFirstAidExpiresOn.length > 0 && clubPermission?.certificateFirstAidExpiresOn != certificateFirstAidExpiresOn) {
      somethingChanged = true;
    }
    if (certificateCprExpiresOn && certificateCprExpiresOn.length > 0 && clubPermission?.certificateCprExpiresOn != certificateCprExpiresOn) {
      somethingChanged = true;
    }
    if (certificateErcExpiresOn && certificateErcExpiresOn.length > 0 && clubPermission?.certificateErcExpiresOn != certificateErcExpiresOn) {
      somethingChanged = true;
    }
    if (certificateLevel1SportsTrainerExpiresOn && certificateLevel1SportsTrainerExpiresOn.length > 0 && clubPermission?.certificateLevel1SportsTrainerExpiresOn != certificateLevel1SportsTrainerExpiresOn) {
      somethingChanged = true;
    }
    return somethingChanged;
  }, [clubPermission, certificateFirstAidExpiresOn, certificateCprExpiresOn, certificateErcExpiresOn, certificateLevel1SportsTrainerExpiresOn])

  const saveLabel = useMemo(() => {
    return isPending ? "Saving..." : "Save"
  }, [isPending, isSuccess, canSave])

  const save = () => {
    const club_permission = {
      certificate_first_aid_expires_on: certificateFirstAidExpiresOn,
      certificate_cpr_expires_on: certificateCprExpiresOn,
      certificate_erc_expires_on: certificateErcExpiresOn,
      certificate_level_1_sports_trainer_expires_on: certificateLevel1SportsTrainerExpiresOn,
    };
    updateClubPermission({ clubPermissionId, club_permission });
    refreshMe({ queryClient });
  };

  const isClubAdmin = useMemo(() => {
    return currentClub?.role === 'club_admin';
  }, [currentClub]);

  const uploadUrl = useMemo(() => {
    const clubPermission = profile?.clubPermissions?.find(
      (u) => (u.club_id = currentClub.clubId),
    );
    const role = clubPermission?.role;
    if (role === 'club_admin') {
      if (currentClub?.club?.settings?.uploadDropboxUrl) {
        return currentClub?.club?.settings?.uploadDropboxUrl
      }
    }
    return null;
  }, [currentClub]);

  useEffect(() => {
    if (error) {
      Alert.alert('Something went wrong', error?.toString(), [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [error]);

  const deleteAccount = () => {
    const title = 'Permanently delete account';
    const description = 'Are you sure?';
    if (Platform.OS === 'web') {
      // eslint-disable-next-line no-alert
      const result = window.confirm(
        [title, description].filter(Boolean).join('\n'),
      );
      if (result) {
        onDeleteAccount();
      }
    } else {
      Alert.alert(title, description, [
        {
          text: 'Cancel',
          style: 'cancel',
        },
        {
          text: 'Yes',
          onPress: () => onDeleteAccount(),
        },
      ]);
    }
  };

  return (
    <Background>
      <BarHeaderLarge title="Profile">
        <BarButton onPress={onLogout}>
          <LogoutText>Logout</LogoutText>
        </BarButton>
      </BarHeaderLarge>
      <VerticalScroll>
        <Centered>
          <Spacer size="large" />
          <FormFieldText label="First Name" value={profile?.profile?.firstName} />
          <FormFieldText label="Last Name" value={profile?.profile?.lastName} />
          <FormFieldText label="Email Address" value={profile?.email} />
          {false && (
            <FormFieldTextMulti
              label={"Club" + (profile?.clubPermissions?.length > 1 ? "s" : "")}
              value={profile?.clubPermissions?.map((clubPermission) => clubPermission.club.name).join('\n')}
            />
          )}
          {me?.clubPermissions ? (
            <FormTableSelect
              label={'I am a...'}
              values={
                me.clubPermissions.map((clubPermission) => ({
                  key: clubPermission.id,
                  value: `${clubPermission.roleFormatted} for ${clubPermission.club?.nickname || clubPermission.club?.abbreviation || clubPermission.club?.name}`,
                }))
              }
              initialKeys={[currentClub?.id]}
              multiselect={false}
              onSelected={(selected) => {
                if (selected.length > 0) {
                  const clubPermission = me.clubPermissions.find(
                    (clubPermission) => (clubPermission.id === selected[0]),
                  );
                  navigation.reset({
                    routes: [{ name: 'ReportsScreen' }]
                  });
                  navigation.reset({
                    routes: [{ name: 'DocumentsScreen' }]
                  });
                  navigation.reset({
                    routes: [{ name: 'PlayersScreen' }]
                  });
                  navigation.reset({
                    routes: [{ name: 'ClubUsersScreen' }]
                  });
                  navigation.reset({
                    routes: [{ name: 'TeamsScreen' }]
                  });
                  queryClient.removeQueries();
                  setCurrentClub(clubPermission);
                }
              }}
            />
          ) : (
            <>
              <FormTableSelect
                label={'I am a...'}
                values={[]}
              />
              <Loading isLoading={true} />
            </>
          )}
          <FormFieldHeading
            label="My Certificates Expiry Dates"
          />
          <FormFieldMinimalTextDate
            label="First Aid"
            required={false}
            value={certificateFirstAidExpiresOn}
            onBlur={(value) => {
              setCertificateFirstAidExpiresOn(value);
            }}
          />
          <FormFieldMinimalTextDate
            label="CPR"
            required={false}
            value={certificateCprExpiresOn}
            onBlur={(value) => {
              setCertificateCprExpiresOn(value);
            }}
          />
          <FormFieldMinimalTextDate
            label="ERC"
            required={false}
            value={certificateErcExpiresOn}
            onBlur={(value) => {
              setCertificateErcExpiresOn(value);
            }}
          />
          <FormFieldMinimalTextDate
            label="Level 1 Sports Trainer"
            required={false}
            value={certificateLevel1SportsTrainerExpiresOn}
            onBlur={(value) => {
              setCertificateLevel1SportsTrainerExpiresOn(value);
            }}
          />
          <Spacer size="large" />
          {isClubAdmin && (
            <SizeClassView size={sizeClasses.compact}>
              <FormTableSelect
                label={'Club Admin'}
                values={[
                  { key: 'clubUsers', value: 'Users' },
                ]}
                onSelected={(selected) => {
                  if (selected?.includes('clubUsers')) {
                    navigation.navigate('ClubUsersModal')
                  }
                }}
                multiselect={false}
                showSelection={false}
              />
            </SizeClassView>
          )}

          <Spacer size="large" />
          <Spacer size="large" />
          <Spacer size="large" />
          {uploadUrl && (
            <ButtonBlue
              label={'Upload file to Dropbox'}
              onPress={() => {
                openLink(uploadUrl);
              }}
            />
          )}
          <Spacer size="large" />
          <Spacer size="large" />
          <FormFieldTextMulti
            label="DELETE ACCOUNT"
            value={
              'Deleting the account is a permanent action. Once deleted, your access to the app will be terminated and your personal profile will be deleted.'
            }
            isWarning={true}
          />
          <ButtonWhite label="Delete Account" onPress={deleteAccount} />
        </Centered>
        <Spacer size="large" />
        <Spacer size="large" />
        <Spacer size="large" />
        <Spacer size="large" />
        <Spacer size="large" />
        <Spacer size="large" />
        <Spacer size="large" />
        <Spacer size="large" />
      </VerticalScroll>
      {canSave && (
        <Footer>
          <Centered>
            <ButtonBlue
              label={saveLabel}
              isDisabled={!canSave || isPending}
              onPress={() => {
                save();
              }}
            />
          </Centered>
          <SizeClassView size={sizeClasses.compact}>
            <Spacer type="bottom" size="large" />
          </SizeClassView>
        </Footer>
      )}
    </Background>
  );
};
