import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Text, TouchableOpacity, View } from 'react-native';
import { DataTable } from 'react-native-paper';

import { BarHeaderLarge } from 'components/BarHeaderLarge';
import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { BarMenu } from 'components/BarMenu';
import { DropDownList } from 'components/DropDownList';
import { Loading } from 'components/Loading';
import { SearchTextInput } from 'components/SearchTextInput';
import { sizeClasses, SizeClassView } from 'components/SizeClassView';
import {
  Container,
  BarButton,
  SmallBarButton,
  DataTableView,
  UsersList,
  RefreshControl,
  PlusIcon,
  IconText
} from './styles';

import { ProfileContext } from 'contexts/ProfileContext';
import { useQueryClient } from '@tanstack/react-query';
import { refreshClubUsers, useGetClubUsers } from 'hooks/useClubUser';

import { colors } from 'theme/colors';
import { formattedDate, parseDate } from 'utils/Dates';
import { titleCase } from 'utils/Strings';

export const ClubUsersScreen = ({ navigation, route }) => {
  const queryClient = useQueryClient()
  const { currentClub, currentTeam } = useContext(ProfileContext);
  const clubId = useMemo(() => currentClub?.clubId, [currentClub]);
  const { data: users, isLoading, isError, error } = useGetClubUsers({ clubId });

  const [sortBy, setSortBy] = useState('email');
  const [sortDirection, setSortDirection] = useState('asc')

  const [userSearch, setUserSearch] = useState('');

  const [statusDropDownValue, setStatusDropDownValue] = useState('All Users');
  const statusItems = [
    { name: 'All Users', id: 'All Users' },
    { name: 'Active', id: 'active' },
    { name: 'Deactivated', id: 'deactivated' },
    { name: 'Whitelist', id: 'whitelist' },
  ];

  const [activeDropDown, setActiveDropDown] = useState('');

  useEffect(() => {
    if (!!isError) {
      Alert.alert('Something went wrong', error?.toString(), [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [isError]);

  const displayTeam = (item) => {
    if (item.teams.length === 0) return '';

    var teams = item.teams;
    return teams
      .map((team) => team.name)
  }

  const displayCertificates = (record) => {
    var certificates = [];
    if (record.certificateFirstAidExpiresOn) {
      certificates.push({ key: "First Aid", value: formattedDate(parseDate(record.certificateFirstAidExpiresOn)) })
    }
    if (record.certificateCprExpiresOn) {
      certificates.push({ key: "CPR", value: formattedDate(parseDate(record.certificateCprExpiresOn)) })
    }
    if (record.certificateErcExpiresOn) {
      certificates.push({ key: "ERC", value: formattedDate(parseDate(record.certificateErcExpiresOn)) })
    }
    if (record.certificateLevel1SportsTrainerExpiresOn) {
      certificates.push({ key: "Level 1 Sports Trainer", value: formattedDate(parseDate(record.certificateLevel1SportsTrainerExpiresOn)) })
    }
    return certificates;
  }

  const statusDisabled = (item) => {
    return item.status === 'disabled'
  }

  const displayStatus = (item) => {
    if (statusDisabled(item)) {
      return 'Deactivated';
    }
    return titleCase(item?.status);
  }

  const displayRole = (role) => {
    return role.split('_').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ')
  }

  const filteredUsers = useMemo(() => {
    if (!users) return [];

    var records = users;
    records = records.map((record) => {
      var name = '';
      if (record?.user) {
        const firstName = record?.user?.profile?.firstName ?? '';
        const lastName = record?.user?.profile?.lastName ?? '';
        name = `${firstName} ${lastName}`;
      } else {
        const firstName = record?.invitationFirstName ?? '';
        const lastName = record?.invitationLastName ?? '';
        name = `${firstName} ${lastName}`
      }
      return {
        id: record?.id,
        email: (record?.invitationEmail || record?.user?.email) ?? '',
        name: name,
        role: displayRole(record?.role ?? ''),
        teams: displayTeam(record),
        certificates: displayCertificates(record),
        status: displayStatus(record)
      }
    })

    if (statusDropDownValue !== 'All Users') {
      records = records.filter(record => record.status?.toLowerCase() === statusDropDownValue)
    }
    if (userSearch) {
      records = records.filter(record => {
        const searchText = `${record.email} ${record.name} ${record.teams} ${record.status}`.toLowerCase()
        return searchText.includes(userSearch.toLowerCase())
      })
    }
    records = records.sort((a, b) => {
      const aValue = Array.isArray(a[sortBy]) ? (a[sortBy].length ? a[sortBy][0]?.value ?? a[sortBy][0] : '') : a[sortBy];
      const bValue = Array.isArray(b[sortBy]) ? (b[sortBy].length ? b[sortBy][0]?.value ?? b[sortBy][0] : '') : b[sortBy];
      const direction = sortDirection == 'asc' ? 1 : -1;
      return (aValue.toLowerCase() > bValue.toLowerCase()) ? direction : (aValue.toLowerCase() < bValue.toLowerCase()) ? -direction : 0;
    });
    return records;
  }, [users, statusDropDownValue, userSearch, sortBy, sortDirection]);

  const canManageUsers = useMemo(() => {
    return currentClub?.role === 'club_admin';
  }, [currentClub])

  const navToAddUser = () => navigation.navigate("ClubUserAddScreen");
  const navToUpdateUser = (clubUserId) => navigation.navigate("ClubUserUpdateScreen", { clubUserId });

  const sortColumn = (field) => {
    if (field !== sortBy) return '';

    if (sortDirection == 'asc') {
      return 'ascending'
    } else {
      return 'descending'
    }
  }

  const directionArrow = (direction) => {
    switch (direction) {
      case 'ascending':
        return '↑';
      case 'descending':
        return '↓';
      default:
        return '';
    }
  }

  return (
    <>
      <SizeClassView size={sizeClasses.regular}>
        <BarHeaderLarge title="Users">
          {canManageUsers && (
            <>
              <BarButton onPress={navToAddUser}>
                <PlusIcon />
              </BarButton>
            </>
          )}
        </BarHeaderLarge>
        <BarMenu>
          <View style={{ paddingHorizontal: 8 }}>
            <DropDownList
              placeholder="All Users"
              items={statusItems}
              isActive={activeDropDown == 'users'}
              onOpen={() => {
                setActiveDropDown('users');
              }}
              value={statusDropDownValue}
              // setValue={setStatusDropDownValue}
              onChangeValue={setStatusDropDownValue}
              containerStyle={{
                marginHorizontal: 4
              }}
            />
          </View>
          <View style={{ flex: 1 }}></View>
          <View style={{ width: '30%' }}>
            <SearchTextInput
              placeholder="Search"
              value={userSearch}
              onChangeText={(text) => {
                setUserSearch(text);
              }}
              autoFocus={true}
            />
          </View>
        </BarMenu>
      </SizeClassView>
      <SizeClassView size={sizeClasses.compact}>
        <BarHeaderSmall
          title="Users"
          leftButtonTitle="Close"
          onLeftPress={() => navigation.goBack()}
        >
          {canManageUsers && (
            <>
              <SmallBarButton onPress={navToAddUser}>
                <PlusIcon />
              </SmallBarButton>
            </>
          )}
        </BarHeaderSmall>

        <View style={{ paddingVertical: 8, paddingHorizontal: 2 }}>
          <SearchTextInput
            placeholder="Search"
            value={userSearch}
            onChangeText={(text) => {
              setUserSearch(text);
            }}
          />
        </View>

        <View style={{ paddingBottom: 8, paddingHorizontal: 8, width: '100%' }}>
          <View style={{ width: '50%' }}>
            <DropDownList
              placeholder="All Users"
              items={statusItems}
              isActive={activeDropDown == 'users'}
              onOpen={() => {
                setActiveDropDown('users');
              }}
              value={statusDropDownValue}
              // setValue={setStatusDropDownValue}
              onChangeValue={setStatusDropDownValue}
              containerStyle={{
                marginHorizontal: 4,
                borderWidth: 1,
                borderColor: colors.border,
                borderRadius: 8,
              }}
            />
          </View>
        </View>
      </SizeClassView>
      <Container style={{ zIndex: -10 }}>
        <DataTableView>
          <DataTable.Header>
            <DataTable.Title style={{ flex: 3 }}>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'name') {
                  setSortBy('name');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <Text style={{ color: sortBy == 'name' ? 'black' : null }}>Name</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'name') {
                  setSortBy('name');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <IconText>{directionArrow(sortColumn('name'))}</IconText>
              </TouchableOpacity>
            </DataTable.Title>
            <DataTable.Title style={{ flex: 5 }}>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'email') {
                  setSortBy('email');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <Text style={{ color: sortBy == 'email' ? 'black' : null }}>Email</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'email') {
                  setSortBy('email');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <IconText>{directionArrow(sortColumn('email'))}</IconText>
              </TouchableOpacity>
            </DataTable.Title>
            <DataTable.Title style={{ flex: 2 }}>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'role') {
                  setSortBy('role');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <Text style={{ color: sortBy == 'role' ? 'black' : null }}>Role</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {
                if (sortBy !== 'role') {
                  setSortBy('role');
                  setSortDirection('asc')
                } else {
                  setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                }
              }}>
                <IconText>{directionArrow(sortColumn('role'))}</IconText>
              </TouchableOpacity>
            </DataTable.Title>
            <SizeClassView size={sizeClasses.regular}>
              <DataTable.Title style={{ flex: 5 }}>
                <TouchableOpacity onPress={() => {
                  if (sortBy !== 'teams') {
                    setSortBy('teams');
                    setSortDirection('asc')
                  } else {
                    setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                  }
                }}>
                  <Text style={{ color: sortBy == 'teams' ? 'black' : null }}>Team</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                  if (sortBy !== 'teams') {
                    setSortBy('teams');
                    setSortDirection('asc')
                  } else {
                    setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                  }
                }}>
                  <IconText>{directionArrow(sortColumn('teams'))}</IconText>
                </TouchableOpacity>
              </DataTable.Title>
              <DataTable.Title style={{ flex: 4 }}>
                <TouchableOpacity onPress={() => {
                  if (sortBy !== 'certificates') {
                    setSortBy('certificates');
                    setSortDirection('asc')
                  } else {
                    setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                  }
                }}>
                  <Text style={{ color: sortBy == 'certificates' ? 'black' : null }}>Certificate Expiries</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                  if (sortBy !== 'certificates') {
                    setSortBy('certificates');
                    setSortDirection('asc')
                  } else {
                    setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                  }
                }}>
                  <IconText>{directionArrow(sortColumn('certificates'))}</IconText>
                </TouchableOpacity>
              </DataTable.Title>
              <DataTable.Title style={{ flex: 1 }}>
                <TouchableOpacity onPress={() => {
                  if (sortBy !== 'status') {
                    setSortBy('status');
                    setSortDirection('asc')
                  } else {
                    setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                  }
                }}>
                  <Text style={{ color: sortBy == 'status' ? 'black' : null }}>Status</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                  if (sortBy !== 'status') {
                    setSortBy('status');
                    setSortDirection('asc')
                  } else {
                    setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                  }
                }}>
                  <IconText>{directionArrow(sortColumn('status'))}</IconText>
                </TouchableOpacity>
              </DataTable.Title>
            </SizeClassView>
            <SizeClassView size={sizeClasses.compact}>
              <DataTable.Title style={{ flex: 2 }}>
                <TouchableOpacity onPress={() => {
                  if (sortBy !== 'status') {
                    setSortBy('status');
                    setSortDirection('asc')
                  } else {
                    setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                  }
                }}>
                  <Text style={{ color: sortBy == 'status' ? 'black' : null }}>Status</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => {
                  if (sortBy !== 'status') {
                    setSortBy('status');
                    setSortDirection('asc')
                  } else {
                    setSortDirection(sortDirection == 'asc' ? 'desc' : 'asc')
                  }
                }}>
                  <IconText>{directionArrow(sortColumn('status'))}</IconText>
                </TouchableOpacity>
              </DataTable.Title>
            </SizeClassView>
          </DataTable.Header>
          <UsersList
            data={filteredUsers}
            renderItem={({ item }) => (
              <DataTable.Row
                key={item.id}
                onPress={() => {
                  if (canManageUsers) {
                    navToUpdateUser(item.id);
                  }
                }}
                style={{
                  backgroundColor: statusDisabled(item) ? '#f7f7f7' : 'white',
                }}
              >
                <DataTable.Cell style={{ flex: 3, paddingRight: 8 }} textStyle={{ color: statusDisabled(item) ? '#a5a5a5' : 'black' }}>{item?.name}</DataTable.Cell>
                <DataTable.Cell style={{ flex: 5, paddingRight: 8 }} textStyle={{ color: statusDisabled(item) ? '#a5a5a5' : 'black' }}>{item?.email}</DataTable.Cell>
                <DataTable.Cell style={{ flex: 2, paddingRight: 8 }} textStyle={{ color: statusDisabled(item) ? '#a5a5a5' : 'black' }}>{item?.role}</DataTable.Cell>
                <SizeClassView size={sizeClasses.regular}>
                  <DataTable.Cell style={{ flex: 5, paddingTop: 6, paddingRight: 8 }} textStyle={{ color: statusDisabled(item) ? '#a5a5a5' : 'black' }}>
                    {item?.teams && item?.teams.map((team) => <div style={{ paddingBottom: 6 }}>{team}</div>)}
                  </DataTable.Cell>
                  <DataTable.Cell style={{ flex: 4, paddingTop: 6, paddingRight: 8 }} textStyle={{ color: statusDisabled(item) ? '#a5a5a5' : 'black' }}>
                    {item?.certificates && item?.certificates.map((certificate) => <div style={{ paddingBottom: 6 }}>{certificate.value} <small><font color="#777678">{certificate.key}</font></small></div>)}
                  </DataTable.Cell>
                  <DataTable.Cell style={{ flex: 1 }} textStyle={{ color: statusDisabled(item) ? '#a5a5a5' : 'black' }}>{item?.status}</DataTable.Cell>
                </SizeClassView>
                <SizeClassView size={sizeClasses.compact}>
                  <DataTable.Cell style={{ flex: 2 }} textStyle={{ color: statusDisabled(item) ? '#a5a5a5' : 'black' }}>{item?.status}</DataTable.Cell>
                </SizeClassView>
              </DataTable.Row>
            )}
            keyExtractor={(item) => item.id}
            refreshControl={
              <RefreshControl refreshing={isLoading} onRefresh={() => refreshClubUsers({ queryClient })} />
            }
          />
        </DataTableView>
      </Container>
      <Loading isLoading={isLoading} />
    </>
  );
};
