import React, { useContext, useEffect, useMemo, useState } from 'react';

import { View } from 'react-native';
import { Alert } from 'components/Alert';
import { AvoidingView } from 'components/AvoidingView';
import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { ButtonBlue } from 'components/ButtonBlue';
import { CardInformationRow } from './styles';
import { CardInformation } from 'components/CardInformation';
import { Centered } from 'components/Centered';
import { Confirm } from 'components/Confirm';
import { Footer } from 'components/Footer';
import { FormFieldText } from 'components/FormFieldText';
import { FormFieldTextDate } from 'components/FormFieldTextDate';
import { FormFieldToggle } from 'components/FormFieldToggle';
import { FormTableSelect } from 'components/FormTableSelect';
import { SizeClassView, sizeClasses } from 'components/SizeClassView';
import { Spacer } from 'components/Spacer';
import { Text } from 'components/Text';
import { VerticalScroll } from 'components/VerticalScroll';
import { titleCase } from 'utils/Strings';

import { ProfileContext } from 'contexts/ProfileContext';
import { useQueryClient } from '@tanstack/react-query';
import { refreshMe } from 'hooks/useMe';
import { useGetTeams } from 'hooks/useTeam';
import { useDeleteClubUser, useGetClubUser, useUpdateClubUser } from 'hooks/useClubUser';

export const ClubUserUpdateScreen = ({ navigation, route }) => {
  const queryClient = useQueryClient()
  const { currentClub } = useContext(ProfileContext);
  const clubId = useMemo(() => { return currentClub?.club?.id }, [currentClub]);
  const [recordDeleted, setRecordDeleted] = useState(false);
  const clubUserId = useMemo(() => { return recordDeleted ? null : route?.params?.clubUserId }, [route.params, recordDeleted]);

  const { data: teams } = useGetTeams({ clubId });
  const { data: clubUser } = useGetClubUser({ clubId, clubUserId });
  const { mutate: updateClubUser, isPending, isSuccess, isError, error } = useUpdateClubUser({ clubId });
  const { mutate: deleteClubUser, isPending: isDeleting, isSuccess: hasDeleted, isError: isDeletionError, error: deletionError } = useDeleteClubUser({ clubId });

  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [certificateFirstAidExpiresOn, setCertificateFirstAidExpiresOn] = useState('');
  const [certificateCprExpiresOn, setCertificateCprExpiresOn] = useState('');
  const [certificateErcExpiresOn, setCertificateErcExpiresOn] = useState('');
  const [certificateLevel1SportsTrainerExpiresOn, setCertificateLevel1SportsTrainerExpiresOn] = useState('');
  const [userStatus, setUserStatus] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);

  const [activeDropDown, setActiveDropDown] = useState('');

  useEffect(() => {
    if (!clubUser) return;
    setEmail(clubUser?.invitationEmail);
    setFirstName(clubUser?.invitationFirstName);
    setLastName(clubUser?.invitationLastName);
    setRole([clubUser?.role]);
    setCertificateFirstAidExpiresOn(clubUser?.certificateFirstAidExpiresOn);
    setCertificateCprExpiresOn(clubUser?.certificateCprExpiresOn);
    setCertificateErcExpiresOn(clubUser?.certificateErcExpiresOn);
    setCertificateLevel1SportsTrainerExpiresOn(clubUser?.certificateLevel1SportsTrainerExpiresOn);
    setUserStatus([clubUser?.status]);
    setSelectedTeams(clubUser?.teams?.map((team) => team.id));
  }, [clubUser]);
  
  const teamItems = useMemo(() => {
    if (!teams) return [];

    var newTeams = teams
    return newTeams.map((team) => ({
      key: team.id,
      value: team.name,
    }))

  }, [teams])

  const canUpdateEmail = useMemo(() => {
    return clubUser?.status === 'whitelist'
  }, [clubUser]);

  const canUpdateStatus = useMemo(() => {
    return clubUser?.status !== 'whitelist'
  }, [clubUser]);

  const canUpdateRole = useMemo(() => {
    return clubUser?.status == 'whitelist'
  }, [clubUser]);

  const roleValues = useMemo(() => {
    return [{ key: 'trainer', value: 'Trainer' }, { key: 'club_admin', value: 'Club Admin' }]
  }, [])

  const statusValues = useMemo(() => {
    return [{ key: 'active', value: 'Active' }, { key: 'disabled', value: 'Deactivate' }]
  }, [])

  const displayRole = (role) => {
    return role.split('_').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ')
  }

  const canSave = useMemo(() => {
    if (canUpdateEmail) {
      if (email !== '') {
        // let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        let reg = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
        if (reg.test(email) === false) { return false }
      } else {
        return canUpdateStatus;
      }
    }
    return true;
  }, [email, canUpdateStatus])

  const saveLabel = useMemo(() => {
    return isPending ? "Saving..." : "Save"
  }, [isPending, canSave])

  const save = () => {
    const club_user = canUpdateEmail ? (
      {
        invitation_email: email,
        invitation_first_name: firstName,
        invitation_last_name: lastName,
        certificate_first_aid_expires_on: certificateFirstAidExpiresOn,
        certificate_cpr_expires_on: certificateCprExpiresOn,
        certificate_erc_expires_on: certificateErcExpiresOn,
        certificate_level_1_sports_trainer_expires_on: certificateLevel1SportsTrainerExpiresOn,
        status: userStatus[0],
        role: role[0],
        teams: selectedTeams,
        send_welcome_email: sendWelcomeEmail
      }
    ) : (
      {
        status: userStatus[0],
        certificate_first_aid_expires_on: certificateFirstAidExpiresOn,
        certificate_cpr_expires_on: certificateCprExpiresOn,
        certificate_erc_expires_on: certificateErcExpiresOn,
        certificate_level_1_sports_trainer_expires_on: certificateLevel1SportsTrainerExpiresOn,
        teams: selectedTeams
      }
    )
    updateClubUser({ clubId, clubUserId, club_user });
  };

  const deleteRecord = () => {
    Confirm('Confirm Delete', `Delete ${email}`, () => {
      deleteClubUser({ clubId, clubUserId });
      setRecordDeleted(true);
    }, () => { });
  };

  useEffect(() => {
    if (isError) Alert('Something went wrong', error?.toString());
  }, [isError]);

  useEffect(() => {
    if (isSuccess || hasDeleted) {
      refreshMe({ queryClient });
      navigation.navigate('ClubUsersScreen');
    }
  }, [isSuccess, hasDeleted]);

  return (
    <>
      <BarHeaderSmall
        title={'Update user'}
        leftButtonTitle="Cancel"
        onLeftPress={() => {
          navigation.navigate('ClubUsersScreen');
        }}
        rightButtonTitle={'Delete'}
        onRightPress={deleteRecord}
      />
      <AvoidingView>
        <SizeClassView size={sizeClasses.regular}>
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View style={{ flex: 1 }}>
              <VerticalScroll>
                <Centered>
                  {canUpdateEmail ? (
                    <>
                      <FormFieldText
                        label="Email"
                        value={email}
                        onChangeText={setEmail}
                        keyboardType="email-address"
                        autoCapitalize={false}
                        autoFocus={true}
                      />
                      <FormFieldToggle
                        label="Send a welcome email?"
                        initialValue={sendWelcomeEmail}
                        onSelected={setSendWelcomeEmail}
                      />
                      <FormFieldText
                        label="First Name"
                        value={firstName}
                        onChangeText={setFirstName}
                      />
                      <FormFieldText
                        label="Last Name"
                        value={lastName}
                        onChangeText={setLastName}
                      />
                    </>
                  ) : (
                    <>
                      <CardInformationRow>
                        <CardInformation title='First Name'>
                          {clubUser?.user?.profile?.firstName}
                        </CardInformation>
                        <CardInformation title='Last Name'>
                          {clubUser?.user?.profile?.lastName}
                        </CardInformation>
                      </CardInformationRow>
                      <CardInformationRow>
                        <CardInformation title='Email'>
                          {clubUser?.user?.email}
                        </CardInformation>
                        {!canUpdateRole && (
                          <CardInformation title='Role'>
                            {displayRole(clubUser?.role ?? '')}
                          </CardInformation>
                        )}
                      </CardInformationRow>
                    </>
                  )}
                  {canUpdateStatus && (
                    <FormTableSelect
                      label={'Status'}
                      values={statusValues}
                      initialKeys={userStatus}
                      multiselect={false}
                      onSelected={setUserStatus}
                    />
                  )}
                  {canUpdateRole && (
                    <FormTableSelect
                      label={'Role'}
                      values={roleValues}
                      initialKeys={role}
                      multiselect={false}
                      onSelected={setRole}
                    />
                  )}
                  <FormFieldTextDate
                    label="First Aid certificate expiry (3 year coverage)"
                    required={false}
                    value={certificateFirstAidExpiresOn}
                    onBlur={(value) => {
                      setCertificateFirstAidExpiresOn(value);
                    }}
                  />
                  <FormFieldTextDate
                    label="CPR certificate expiry (1 year coverage)"
                    required={false}
                    value={certificateCprExpiresOn}
                    onBlur={(value) => {
                      setCertificateCprExpiresOn(value);
                    }}
                  />
                  <FormFieldTextDate
                    label="ERC certificate expiry"
                    required={false}
                    value={certificateErcExpiresOn}
                    onBlur={(value) => {
                      setCertificateErcExpiresOn(value);
                    }}
                  />
                  <FormFieldTextDate
                    label="Level 1 Sports Trainer certificate expiry"
                    required={false}
                    value={certificateLevel1SportsTrainerExpiresOn}
                    onBlur={(value) => {
                      setCertificateLevel1SportsTrainerExpiresOn(value);
                    }}
                  />
                  <Spacer type="bottom" size="large" />
                </Centered>
              </VerticalScroll>
            </View>
            <View style={{ flex: 1 }}>
              {role == 'trainer' && (
                <>
                  <VerticalScroll>
                    <Centered>
                      {teamItems.length == 0 ? (
                        <>
                          <Spacer type="bottom" size="large" />
                          <Text style={{ textAlign: 'center' }}>No teams yet</Text>
                          <Spacer type="bottom" size="large" />
                        </>
                      ) : (
                        <FormTableSelect
                          label={'Assign teams'}
                          values={teamItems}
                          initialKeys={selectedTeams}
                          multiselect={true}
                          onSelected={setSelectedTeams}
                        />
                      )}
                      <Spacer type="bottom" size="large" />
                    </Centered>
                  </VerticalScroll>
                </>
              )}
            </View>
          </View>
        </SizeClassView>
        <SizeClassView size={sizeClasses.compact}>
          <VerticalScroll>
            <Centered>
              {canUpdateEmail ? (
                <>
                  <FormFieldText
                    label="Email"
                    value={email}
                    onChangeText={setEmail}
                    keyboardType="email-address"
                    autoCapitalize={false}
                    autoFocus={true}
                  />
                  <FormFieldToggle
                    label="Send a welcome email?"
                    initialValue={sendWelcomeEmail}
                    onSelected={setSendWelcomeEmail}
                  />
                  <FormFieldText
                    label="First Name"
                    value={firstName}
                    onChangeText={setFirstName}
                  />
                  <FormFieldText
                    label="Last Name"
                    value={lastName}
                    onChangeText={setLastName}
                  />
                </>
              ) : (
                <>
                  <CardInformationRow>
                    <CardInformation title='First Name'>
                      {clubUser?.user?.profile?.firstName}
                    </CardInformation>
                    <CardInformation title='Last Name'>
                      {clubUser?.user?.profile?.lastName}
                    </CardInformation>
                  </CardInformationRow>
                  <CardInformationRow>
                    <CardInformation title='Email'>
                      {clubUser?.user?.email}
                    </CardInformation>
                    {!canUpdateRole && (
                      <CardInformation title='Role'>
                        {displayRole(clubUser?.role ?? '')}
                      </CardInformation>
                    )}
                  </CardInformationRow>
                </>
              )}
              {canUpdateRole && (
                <FormTableSelect
                  label={'Role'}
                  values={roleValues}
                  initialKeys={role}
                  multiselect={false}
                  onSelected={setRole}
                />
              )}
              <CardInformationRow>
                <CardInformation title='Added to whitelist'>
                  {clubUser?.createdAtShort}
                </CardInformation>
                {canUpdateStatus && (
                  <CardInformation title='User Activated'>
                    {clubUser?.user?.createdAtShort}
                  </CardInformation>
                )}
                {!canUpdateStatus && (
                  <CardInformation title='Status'>
                    {titleCase(clubUser?.status)}
                  </CardInformation>
                )}
              </CardInformationRow>
              {canUpdateStatus && (
                <FormTableSelect
                  label={'Status'}
                  values={statusValues}
                  initialKeys={userStatus}
                  multiselect={false}
                  onSelected={setUserStatus}
                />
              )}
              <FormFieldTextDate
                label="First Aid certificate expiry (3 year coverage)"
                required={false}
                value={certificateFirstAidExpiresOn}
                onBlur={(value) => {
                  setCertificateFirstAidExpiresOn(value);
                }}
              />
              <FormFieldTextDate
                label="CPR certificate expiry (1 year coverage)"
                required={false}
                value={certificateCprExpiresOn}
                onBlur={(value) => {
                  setCertificateCprExpiresOn(value);
                }}
              />
              <FormFieldTextDate
                label="ERC certificate expiry"
                required={false}
                value={certificateErcExpiresOn}
                onBlur={(value) => {
                  setcertificateErcExpiresOn(value);
                }}
              />
              <FormFieldTextDate
                label="Level 1 Sports Trainer certificate expiry"
                required={false}
                value={certificateLevel1SportsTrainerExpiresOn}
                onBlur={(value) => {
                  setCertificateLevel1SportsTrainerExpiresOn(value);
                }}
              />
              <Spacer type="bottom" size="large" />
              {role == 'trainer' && (
                <>
                  {teamItems.length == 0 ? (
                    <>
                      <Spacer type="bottom" size="large" />
                      <Text style={{ textAlign: 'center' }}>No teams yet</Text>
                      <Spacer type="bottom" size="large" />
                    </>
                  ) : (
                    <FormTableSelect
                      label={'Assign teams'}
                      values={teamItems}
                      initialKeys={selectedTeams}
                      multiselect={true}
                      onSelected={setSelectedTeams}
                    />
                  )}
                </>
              )}
              <Spacer type="bottom" size="large" />
            </Centered>
          </VerticalScroll>
        </SizeClassView>
        <Footer>
          <Centered>
            <ButtonBlue
              label={saveLabel}
              isDisabled={!canSave}
              onPress={() => {
                save();
              }}
            />
          </Centered>
          <SizeClassView size={sizeClasses.compact}>
            <Spacer type="bottom" size="large" />
          </SizeClassView>
        </Footer>
      </AvoidingView>
    </>
  );
};
