import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateMeClubPermission } from 'api/ProfileApi';

export const useUpdateMeClubPermission = ({ clubPermissionId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateMeClubPermission,
    onSuccess: async (data) => {
      refreshMe({ queryClient })
    },
  })
};

export const refreshMe = ({ queryClient }) => {
  queryClient.invalidateQueries({
    queryKey: queryKey()
  });
};

const queryKey = () => {
  return ['me'];
}