import camelize from 'camelize';
import { parseDateTime, formattedShortDate } from 'utils/Dates';
import { apiSecureDelete, apiSecureGet, apiSecurePost, apiSecurePut } from 'api/ApiService';

export const getDocuments = async ({ queryKey }) => {
  const clubId = queryKey[1];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/documents`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return documentsTransform(data);
};

function dataURItoFile(dataURI, file_name, file_type) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  var blob = new Blob([ia], { type: mimeString });

  return new File([blob], file_name, {
    type: file_type,
  });
}

export const createDocument = async ({ clubId, document }) => {
  var formdata = new FormData();
  formdata.append('document[title]', document.title);
  formdata.append('document[description]', document.description);
  formdata.append('document[weblink]', document.weblink);
  if (document.file?.uri?.startsWith('file')) {
    formdata.append('document[file]', {
      name: document.file.name,
      type: document.file.type,
      uri: document.file.uri,
    });
  }
  if (document.file?.uri?.startsWith('data')) {
    formdata.append(
      'document[file]',
      dataURItoFile(
        document.file.uri,
        document.file.name,
        document.file.mimeType,
      ),
    );
  }

  const response = await apiSecurePost(`/v1/clubs/${clubId}/documents`, formdata)
  if (!response.ok) {
    const json = await response.json();
    var errorMessages = '';
    if (json.errors) {
      for (const [key, value] of Object.entries(json.errors)) {
        errorMessages += `${key} ${value}\n`;
      }
    }
    if (!errorMessages) {
      errorMessages = 'Network response was not ok';
    }
    throw new Error(`${errorMessages}`);
  }
  const data = await response.json();
  return documentTransform(data);
};

export const updateDocument = async ({ clubId, document }) => {
  var formdata = new FormData();
  formdata.append('document[title]', document.title);
  formdata.append('document[description]', document.description);
  formdata.append('document[weblink]', document.weblink);
  if (document.file?.uri?.startsWith('file')) {
    formdata.append('document[file]', {
      name: document.file.name,
      type: document.file.type,
      uri: document.file.uri,
    });
  }
  if (document.file?.uri?.startsWith('data')) {
    formdata.append(
      'document[file]',
      dataURItoFile(document.file.uri, document.file.name, document.file.type),
    );
  }

  const response = await apiSecurePut(`/v1/clubs/${clubId}/documents/${document.id}`, formdata)
  if (!response.ok) {
    const json = await response.json();
    var errorMessages = '';
    if (json.errors) {
      for (const [key, value] of Object.entries(json.errors)) {
        errorMessages += `${key} ${value}\n`;
      }
    }
    if (!errorMessages) {
      errorMessages = 'Network response was not ok';
    }
    throw new Error(`${errorMessages}`);
  }
  const data = await response.json();
  return documentTransform(data);

};

export const deleteDocument = async ({ clubId, documentId }) => {
  const response = await apiSecureDelete(`/v1/clubs/${clubId}/documents/${documentId}`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
};

const transform = (result = {}) => {
  result.createdAtShort = formattedShortDate(parseDateTime(result.created_at));
  result.updatedAtShort = formattedShortDate(parseDateTime(result.updated_at));
  return camelize(result);
};

export const documentTransform = (result = {}) => {
  return transform(result.data);
};

export const documentsTransform = (results = {}) => {
  return results.data.map((result) => transform(result));
};
