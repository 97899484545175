import React, { useContext, useState, useMemo, useEffect } from 'react';
import styled from 'styled-components/native';
import { DataTable } from 'react-native-paper';
import { openLink } from 'utils/links';

import { Alert } from 'components/Alert';
import { BarMenu } from 'components/BarMenu';
import { Loading } from 'components/Loading';
import { BarButton, RefreshText } from './styles';

import { useQueryClient } from '@tanstack/react-query';
import { ProfileContext } from 'contexts/ProfileContext';
import { refreshDocuments, useGetDocuments } from 'hooks/useDocument';

const Container = styled.View``;
const DataTableView = styled(DataTable)`
  background-color: white;
`;
const DataTableTitleCenter = styled(DataTable.Title).attrs({
  justifyContent: 'center',
})``;
const DataTableCellCenter = styled(DataTable.Cell).attrs({
  justifyContent: 'center',
})``;

const optionsPerPage = [2, 3, 4];

export const DocumentListRegular = ({ navigation }) => {
  const { currentClub } = useContext(ProfileContext);
  const clubId = useMemo(() => currentClub?.clubId, [currentClub]);

  const { data: documents, isLoading, isError, error } = useGetDocuments({ clubId })

  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(optionsPerPage[0]);

  const canAddDocument = useMemo(() => {
    return currentClub?.role === 'club_admin';
  }, [currentClub]);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (error) {
      Alert('Something went wrong', error?.toString(), [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [error]);

  return (
    <Container>
      <BarMenu>
        <BarButton
          onPress={() => {
            if (clubId) {
              refreshDocuments({ queryClient, clubId });
            }
          }}
        >
          <RefreshText>Refresh</RefreshText>
        </BarButton>
      </BarMenu>
      <DataTableView>
        <DataTable.Header>
          <DataTable.Title style={{ flex: 3 }}>Title</DataTable.Title>
          <DataTable.Title style={{ flex: 3 }}>Description</DataTable.Title>
          <DataTableTitleCenter style={{ flex: 1 }}>Date Updated</DataTableTitleCenter>
        </DataTable.Header>
        {documents && documents?.map((document) => (
          <DataTable.Row
            key={document?.id}
            onPress={() => {
              if (canAddDocument) {
                navigation.navigate('DocumentDetailsScreen', { document });
              } else {
                if (document?.weblink) {
                  openLink(document?.weblink);
                } else {
                  openLink(document?.file?.url);
                }
              }
            }}
          >
            <DataTable.Cell style={{ flex: 3 }}>{document?.title}</DataTable.Cell>
            <DataTable.Cell style={{ flex: 3 }}>{document?.description}</DataTable.Cell>
            <DataTableCellCenter style={{ flex: 1 }}>{document?.updatedAtShort}</DataTableCellCenter>
          </DataTable.Row>
        ))}
      </DataTableView>
      <Loading isLoading={isLoading} />
    </Container>
  );
};
