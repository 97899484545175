import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createClubUser, deleteClubUser, getClubUser, getClubUsers, updateClubUser } from '../api/ClubUsersApi';

export const useGetClubUsers = ({ clubId }) => {
  return useQuery({
    queryKey: queryKey({ clubId }),
    queryFn: getClubUsers,
    refetchInterval: 1000 * 60, // every minute
    enabled: !!clubId
  });
};

export const useGetClubUser = ({ clubId, clubUserId }) => {
  return useQuery({
    queryKey: queryKey({ clubId, clubUserId }),
    queryFn: getClubUser,
    enabled: !!clubId && !!clubUserId
  });
};

export const useCreateClubUser = ({ clubId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createClubUser,
    onSuccess: async (data) => {
      if (!clubId) return;
      refreshClubUsers({ queryClient, clubId });
    },
  })
};

export const useUpdateClubUser = ({ clubId, clubUserId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateClubUser,
    onSuccess: async (data) => {
      queryClient.setQueryData(['clubs', clubId, 'users', clubUserId], data)
      refreshClubUsers({ queryClient, clubId });
    },
  })
};

export const useDeleteClubUser = ({ clubId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteClubUser,
    onSuccess: async (data) => {
      refreshClubUsers({ queryClient, clubId });
    },
  })
};

export const refreshClubUsers = ({ queryClient, clubId }) => {
  queryClient.invalidateQueries({
    queryKey: queryKey({ clubId })
  });
};

const queryKey = ({ clubId, clubUserId }) => {
  if (clubUserId) {
    return ['clubs', clubId, 'users', clubUserId];
  } else {
    return ['clubs', clubId, 'users'];
  }
}
