import React, { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Alert, Text, View } from 'react-native';

import { BarMenu } from 'components/BarMenu';
import { DropDownList } from 'components/DropDownList';
import { SearchTextInput } from 'components/SearchTextInput';
import { VerticalScroll } from 'components/VerticalScroll';
import {
  BarButton,
  Container,
  DataTableCell,
  DataTableRow,
  DataTableCellCenter,
  DataTableHeader,
  DataTableTitle,
  DataTableTitleCenter,
  DataTableView,
  DownloadText,
  RefreshText,
  RefreshTextDisabled,
} from './styles.regular';

import { ProfileContext } from 'contexts/ProfileContext';
import { useQueryClient } from '@tanstack/react-query';
import { useGetReports, refreshReports } from 'hooks/useReport';
import { useGetRounds } from 'hooks/useRound';
import { useGetSeasons } from 'hooks/useSeason';
import { getReportsExport } from 'api/ReportsApi';

export const ReportListRegular = ({ navigation }) => {
  const queryClient = useQueryClient()
  const { profile, currentClub, currentFeatures } = useContext(ProfileContext);
  const clubId = useMemo(() => currentClub?.clubId, [currentClub]);
  const leagueId = useMemo(() => {
    if (currentClub) {
      return currentClub?.club.affiliations[0]?.leagueId;
    }
  }, [currentClub]);

  const { data: rounds } = useGetRounds({ leagueId });
  const { data: reports, isError, error } = useGetReports({ clubId });
  const { data: seasons } = useGetSeasons({ leagueId });

  const [season, setSeason] = useState('All Seasons');
  const [currentRounds, setCurrentRounds] = useState([]);

  const canExportReports = useMemo(() => {
    return (currentClub?.role === 'club_admin' && currentFeatures?.includes('export_reports')) || false;
  }, [currentClub, currentFeatures]);

  const [reportSearch, setReportSearch] = useState('');

  const [exportData, setExportData] = useState('');
  const [exportFilename, setExportFilename] = useState('');
  const [exporting, setExporting] = useState(false);

  const [activeDropDown, setActiveDropDown] = useState('');
  const [roundsDropDownOpen, setRoundsDropDownOpen] = useState(false);
  const [roundId, setRoundId] = useState([]);
  const roundItems = useMemo(() => {
    if (!rounds) return [];

    var results = rounds;

    if (season !== 'All Seasons') {
      results = results.filter((result) => result.season?.name?.includes(season))
    } else {
      results = []
    }
    results = results.map((round) => { return { id: round.id, name: `${round.season.name} ${round.name}` } })
    return [
      ...results,
    ];
  }, [rounds, season])

  const [statusDropDownOpen, setStatusDropDownOpen] = useState(false);
  const [statusValues, setStatusValues] = useState([]);
  const statusItems = [
    { name: 'Pending assessment', id: 'pending_assessment' },
    { name: 'Under assessment', id: 'under_assessment' },
    { name: 'Not cleared to play', id: 'not_cleared_to_play' },
    { name: 'Closed cleared to play', id: 'closed_cleared_to_play' },
  ];

  useLayoutEffect(() => {
    if (seasons) {
      var today = new Date()
      var found = seasons.find((season) => {
        var startsOn = Date.parse(season.startsOn)
        var endsOn = Date.parse(season.endsOn)
        return today > startsOn && today < endsOn;
      })
      setSeason(found?.name ?? 'All Seasons')
    }
  }, [seasons]);

  const seasonItems = useMemo(() => {
    if (!seasons) return [];

    return [
      { id: 'All Seasons', name: 'All Seasons', },
      ...seasons
        .map((season) => { return { id: season.name, name: `Season ${season.name}` } })
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? -1 : (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : 0)
    ];
  }, [seasons])

  useEffect(() => {
    if (isError) {
      Alert.alert('Something went wrong', error?.toString(), [
        { text: 'OK', onPress: null },
      ]);
    }
  }, [error]);

  useEffect(() => {
    if (roundsDropDownOpen) {
      setStatusDropDownOpen(false);
    }
  }, [roundsDropDownOpen])

  useEffect(() => {
    if (statusDropDownOpen) {
      setRoundsDropDownOpen(false);
    }
  }, [statusDropDownOpen])

  const filteredReports = useMemo(() => {
    if (!reports) return [];

    var results = reports
    if (currentClub?.role === 'trainer') {
      var teamIds = currentClub.teams.map(team => team.id);
      results = results.filter(result => teamIds.includes(result.teamDetails?.id) || result?.userId == profile?.id)
    }
    if (reportSearch) {
      results = results.filter(record => {
        const playerText = `${record?.playerDetails?.firstName} ${record?.playerDetails?.lastName}`
        const injuryText = record?.fieldData?.injuries?.join(" ");
        const statusText = record?.statusShort;
        const suspectedText = record?.fieldData?.suspectedConcussion?.toLowerCase() == 'yes' && 'suspected';
        const confirmedText = record?.metaData?.concussionConfirmed?.toLowerCase() == 'yes' && 'confirmed';
        const submittedByText = record?.fieldData?.submittedBy
        const searchText = `${playerText} ${injuryText} ${statusText} ${suspectedText} ${confirmedText} ${submittedByText}`.toLowerCase()
        return searchText.includes(reportSearch.toLowerCase())
      })
    }

    if (season !== 'All Seasons') {
      results = results.filter((result) => result.gameDetails?.season == season)
      if (currentRounds.length > 0) {
        const currentRoundNames = currentRounds.map((round) => round.name);
        results = results.filter((result) => currentRoundNames.includes(result.gameDetails?.round))
      }
    }
    if (statusValues.length > 0) {
      results = results.filter(result => statusValues.includes(result.status))
    }
    return results;
  }, [currentClub, currentRounds, season, statusValues, reportSearch, reports]);

  const getExportData = () => {
    getReportsExport({ club_id: currentClub.clubId })
      .then((results) => {
        setTimeout(() => {
          setExportData(results);
          const category = 'medical-report';
          const today = new Date().toISOString().split('T')[0];
          setExportFilename(category + '-' + today + '.csv');
          setExporting(false);
        }, 1000);
      })
      .catch(() => {
        setExporting(false);
      });
  };

  const Downloadlink = (props) => {
    const streamData =
      'data:application/octet-stream,' + encodeURIComponent(props.data);
    return (
      <a
        href={streamData}
        download={props.filename}
        style={{ textDecoration: 'none', padding: 0, margin: 0 }}
      >
        {props.children}
      </a>
    );
  };

  return (
    <Container>
      <BarMenu>
        {canExportReports && !exportData && (
          <BarButton
            onPress={() => {
              if (!exporting) {
                setExporting(true);
                getExportData();
              }
            }}
          >
            {exporting ? (
              <RefreshTextDisabled>Generating</RefreshTextDisabled>
            ) : (
              <RefreshText>Generate CSV</RefreshText>
            )}
          </BarButton>
        )}
        {canExportReports && !!exportData && (
          <BarButton>
            <Downloadlink data={exportData} filename={exportFilename}>
              <DownloadText>Export CSV</DownloadText>
            </Downloadlink>
          </BarButton>
        )}
        <DropDownList
          placeholder="All Rounds"
          items={roundItems}
          multiple={true}
          isActive={activeDropDown == 'rounds'}
          enabled={season !== 'All Seasons'}
          onOpen={() => {
            setActiveDropDown('rounds');
          }}
          value={roundId}
          setValue={setRoundId}
          onChangeValue={(roundIds) => {
            if (rounds) {
              const pickedRounds = rounds.filter(round => roundIds.includes(round.id));
              setCurrentRounds(pickedRounds);
            }
          }}
          style={{
            marginLeft: 8,
          }}
          containerStyle={{
            width: '20%', marginHorizontal: 4
          }}
        />
        <DropDownList
          placeholder="All Seasons"
          items={seasonItems}
          multiple={false}
          isActive={activeDropDown == 'seasons'}
          onOpen={() => {
            setActiveDropDown('seasons');
          }}
          value={season}
          onChangeValue={(value) => {
            setCurrentRounds([]);
            setSeason(value);
          }}
          style={{
            marginLeft: 8,
            marginRight: 16,
          }}
          containerStyle={{
            width: '20%',
            marginHorizontal: 4
          }}
        />

        <DropDownList
          placeholder="All Reports"
          items={statusItems}
          multiple={true}
          isActive={activeDropDown == 'reports'}
          onOpen={() => {
            setActiveDropDown('reports');
          }}
          value={statusValues}
          onChangeValue={setStatusValues}
          containerStyle={{
            width: '20%', marginHorizontal: 4
          }}
        />

        <View style={{ flex: 1 }}></View>
        <View style={{ width: '25%' }}>
          <SearchTextInput
            placeholder="Search"
            value={reportSearch}
            onChangeText={(text) => {
              setReportSearch(text);
            }}
            autoFocus={true}
          />
        </View>
      </BarMenu>
      <DataTableView style={{ zIndex: -10 }}>
        <DataTableHeader>
          <DataTableTitle onPress={() => console.log('Player Name')}>
            Player Name
          </DataTableTitle>
          <DataTableTitle>Injury</DataTableTitle>
          <DataTableTitleCenter>Suspected Concussion</DataTableTitleCenter>
          <DataTableTitleCenter>Status</DataTableTitleCenter>
          <DataTableTitleCenter>Submitted By</DataTableTitleCenter>
          <DataTableTitleCenter>Incident Date</DataTableTitleCenter>
        </DataTableHeader>
        <VerticalScroll>
          {filteredReports?.map((report) => (
            <DataTableRow
              key={report?.id}
              onPress={() => {
                navigation.navigate('ReportDetailsScreen', {
                  reportId: report.id
                });
              }}
              style={{
                backgroundColor:
                  report?.status === 'closed_cleared_to_play' ? '#f7f7f7' : 'white',
              }}
            >
              <DataTableCell>
                <Text
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{
                    color: report?.status === 'closed_cleared_to_play' ? '#a5a5a5' : 'black',
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  {report?.fieldData?.player}
                </Text>
              </DataTableCell>
              <DataTableCell>
                <Text
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{
                    color: report?.status === 'closed_cleared_to_play' ? '#a5a5a5' : 'black',
                    fontSize: 16,
                  }}
                >
                  {report?.fieldData?.injuries?.filter(Boolean)?.join(', ')}
                </Text>
              </DataTableCell>
              <DataTableCellCenter>
                <Text
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{
                    color: report?.status === 'closed_cleared_to_play' ? '#a5a5a5' : 'black',
                    fontSize: 16,
                  }}
                >
                  {report?.fieldData?.suspectedConcussion}
                </Text>
              </DataTableCellCenter>
              <DataTableCellCenter>
                <Text
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{
                    color: report?.status === 'closed_cleared_to_play' ? '#a5a5a5' : 'black',
                    fontSize: 16,
                  }}
                >
                  {report?.statusShort}
                </Text>
              </DataTableCellCenter>
              <DataTableCellCenter>
                <Text
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{
                    color: report?.status === 'closed_cleared_to_play' ? '#a5a5a5' : 'black',
                    fontSize: 16,
                  }}
                >
                  {report?.fieldData?.submittedBy}
                </Text>
              </DataTableCellCenter>
              <DataTableCellCenter>
                <Text
                  // eslint-disable-next-line react-native/no-inline-styles
                  style={{
                    color: report?.status === 'closed_cleared_to_play' ? '#a5a5a5' : 'black',
                    fontSize: 16,
                  }}
                >
                  {report?.fieldData?.incidentOnShort}
                </Text>
              </DataTableCellCenter>
            </DataTableRow>
          ))}
        </VerticalScroll>
      </DataTableView>
    </Container>
  );
};
