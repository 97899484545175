import React, { useContext, useEffect, useMemo, useState } from 'react';

import { Platform, View } from 'react-native';
import { BarMenu } from 'components/BarMenu';
import { DropDownList } from 'components/DropDownList';
import { BarButton, BarTitleText, DownloadText } from './styles';

import { ProfileContext } from 'contexts/ProfileContext';
import { useGetRounds } from 'hooks/useRound';
import { useGetSeasons } from 'hooks/useSeason';
import { useGetSeniorClubRoundPdf } from 'hooks/useReport';
import { colors } from 'theme/colors';

export const RoundSummarySeniorClubs = () => {
  const [activeDropDown, setActiveDropDown] = useState('');
  const [seasonName, setSeasonName] = useState('');
  const [roundId, setRoundId] = useState(null);
  const [requestPDF, setRequestRoundPDF] = useState(false);

  const { currentClub } = useContext(ProfileContext);
  const clubId = useMemo(() => { return currentClub?.club?.id }, [currentClub]);
  const leagueId = useMemo(() => { return currentClub?.club?.affiliations[0]?.leagueId }, [currentClub]);
  const isVisible = useMemo(() => { return currentClub?.club?.affiliations[0]?.insights?.roundSummarySeniorClubPdf }, [currentClub]);
  const [roundBorderColor, setRoundBorderColor] = useState(colors.border)


  const { data: seasons } = useGetSeasons({ leagueId });
  const { data: rounds } = useGetRounds({ leagueId });
  const { data: reportPdf, refetch: refreshPdf } = useGetSeniorClubRoundPdf({ clubId, roundId })

  const seasonItems = useMemo(() => {
    if (!seasons) return [];

    return [
      ...seasons
        .map((season) => { return { id: season.name, name: `Season ${season.name}` } })
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? -1 : (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : 0)
    ];
  }, [seasons])

  const roundItems = useMemo(() => {
    if (!rounds) return [];

    var results = rounds;
    results = results.filter((result) => result.season?.name?.includes(seasonName))

    if (seasonName) {
      results = results.filter((result) => result.season?.name?.includes(seasonName))
    } else {
      results = []
    }
    results = results.map((round) => { return { id: round.id, name: round.name } })
    return [
      { id: null, name: 'Choose a Round', },
      ...results,
    ];
  }, [rounds, seasonName])

  useEffect(() => {
    if (!seasons) return;
    if (seasons.length == 0) return;
    if (!seasonName) {
      setSeasonName(seasons[seasons?.length - 1]?.name);
    }
  }, [seasons]);

  useEffect(() => {
    if (!requestPDF) return;
    setRequestRoundPDF(false);
    if (!!reportPdf) {
      const clubAbbreviation = currentClub?.club?.abbreviation ?? "Club";
      const roundIndex = rounds.findIndex((round) => round.id === roundId);
      const roundName = rounds[roundIndex]?.name;
      const seasonName = rounds[roundIndex]?.season?.name;
      const filename = `${clubAbbreviation} - Round Summary - ${roundName}, ${seasonName}.pdf`;

      if (Platform.OS === 'web') {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(reportPdf);
        link.download = filename;
        document.body.append(link);
        link.click();
        link.remove();
      } else {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          const fileUri = `${FileSystem.documentDirectory}/${encodeURIComponent(filename)}`;
          await FileSystem.writeAsStringAsync(fileUri, fileReader.result.split(',')[1], { encoding: FileSystem.EncodingType.Base64 });
          await Share.share({
            message: filename,
            title: "Share file",
            url: fileUri,
          });
        }
        fileReader.readAsDataURL(reportPdf);
      }
    }
  }, [reportPdf])

  const downloadPDF = () => {
    if (!roundId) {
      setRoundBorderColor('red');
      return
    }
    setRoundBorderColor(colors.border);

    setRequestRoundPDF(true);
    refreshPdf();
  };

  return (
    <>
      {isVisible && (
        <BarMenu>
          <BarButton onPress={downloadPDF}>
            <DownloadText style={{ color: colors.headfirst.green }}>Print PDF</DownloadText>
          </BarButton>
          <View style={{ flex: 1 }}></View>
          <DropDownList
            placeholder="All Rounds"
            items={roundItems}
            isActive={activeDropDown == 'rounds'}
            onOpen={() => {
              setActiveDropDown('rounds');
            }}
            value={roundId}
            onChangeValue={(roundId) => {
              setRoundId(roundId);
            }}
            style={{
              marginLeft: 8,
              borderColor: `${roundBorderColor}`
            }}
            containerStyle={{
              width: 200, marginHorizontal: 4
            }}
          />
          <DropDownList
            placeholder="All Seasons"
            items={seasonItems}
            isActive={activeDropDown == 'seasons'}
            onOpen={() => {
              setActiveDropDown('seasons');
            }}
            value={seasonName}
            onChangeValue={(value) => {
              setSeasonName(value);
              setRoundId(null);
            }}
            style={{
              marginLeft: 8,
              marginRight: 16,
            }}
            containerStyle={{
              width: 200,
              marginHorizontal: 4
            }}
          />
          <BarTitleText>Round Summary - Senior Clubs</BarTitleText>
        </BarMenu>
      )}
    </>
  )
}
