import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Keyboard, View } from 'react-native';

import { AvoidingView } from 'components/AvoidingView';
import { Background } from 'components/Background';
import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { ButtonBlue } from 'components/ButtonBlue';
import { Centered } from 'components/Centered';
import { Footer } from 'components/Footer';
import { FormFieldText } from 'components/FormFieldText';
import { FormFieldTextMulti } from 'components/FormFieldTextMulti';
import { FormTableSelect } from 'components/FormTableSelect';
import { Loading } from 'components/Loading';
import { SizeClassView, sizeClasses } from 'components/SizeClassView';
import { Spacer } from 'components/Spacer/Spacer';
import { Text } from 'components/Text';
import { VerticalScroll } from 'components/VerticalScroll';

import { ProfileContext } from 'contexts/ProfileContext';
import { useGetPlayer, useUpdatePlayer } from 'hooks/usePlayer';
import { useGetTeams } from 'hooks/useTeam';

export const PlayerUpdateScreen = ({ navigation, route }) => {
  const { currentClub } = useContext(ProfileContext);
  const clubId = useMemo(() => currentClub?.clubId, [currentClub]);
  const [recordDeleted, setRecordDeleted] = useState(false);
  const playerId = useMemo(() => {
    return recordDeleted ? null : route?.params?.playerId
  }, [route.params, recordDeleted]);


  const { data: player } = useGetPlayer({ clubId, playerId });
  const { data: teams } = useGetTeams({ clubId });
  const { mutate: updatePlayer, isPending, isSuccess, isError, error } = useUpdatePlayer({ clubId, playerId });

  const teamItems = useMemo(() => {
    if (!teams) return [];

    var newTeams = teams
    return newTeams.map((team) => ({
      key: team.id,
      value: team.name,
    }))
  }, [teams])

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [jumperNumber, setJumperNumber] = useState('');
  const [additionalInformation, setAdditionalInformation] = useState('');

  const [emergencyContactFirstName, setEmergencyContactFirstName] = useState('');
  const [emergencyContactLastName, setEmergencyContactLastName] = useState('');
  const [emergencyContactMobile, setEmergencyContactMobile] = useState('');
  const [emergencyContactEmail, setEmergencyContactEmail] = useState('');

  const [playerTeams, setPlayerTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);

  useEffect(() => {
    if (!!player) {
      setFirstName(player?.firstName);
      setLastName(player?.lastName);
      setJumperNumber(player?.jumperNumber);
      setAdditionalInformation(player?.additionalInformation);
      setEmergencyContactFirstName(player?.emergencyContactFirstName);
      setEmergencyContactLastName(player?.emergencyContactLastName);
      setEmergencyContactMobile(player?.emergencyContactMobile);
      setEmergencyContactEmail(player?.emergencyContactEmail);
      setPlayerTeams(player?.teamPlayers?.map((teamPlayer) => teamPlayer.teamId));
      setSelectedTeams(player?.teamPlayers?.map((teamPlayer) => teamPlayer.teamId));
    }
  }, [player]);

  const canSave = useMemo(() => {
    return (
      player?.firstName !== firstName ||
      player?.lastName !== lastName ||
      player?.jumperNumber !== jumperNumber ||
      player?.additionalInformation !== additionalInformation ||
      player?.emergencyContactFirstName !== emergencyContactFirstName ||
      player?.emergencyContactLastName !== emergencyContactLastName ||
      player?.emergencyContactMobile !== emergencyContactMobile ||
      player?.emergencyContactEmail !== emergencyContactEmail ||
      JSON.stringify(playerTeams) !== JSON.stringify(selectedTeams)
    ) && selectedTeams.length > 0
  }, [player, firstName, lastName, jumperNumber, additionalInformation, emergencyContactFirstName, emergencyContactLastName, emergencyContactMobile, emergencyContactEmail, selectedTeams])

  const saveLabel = useMemo(() => {
    return isPending ? "Saving..." : "Save"
  }, [isPending, canSave])

  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  const save = () => {
    const player = {
      first_name: firstName,
      last_name: lastName,
      jumper_number: jumperNumber,
      additional_information: additionalInformation,
      emergency_contact_first_name: emergencyContactFirstName,
      emergency_contact_last_name: emergencyContactLastName,
      emergency_contact_mobile: emergencyContactMobile,
      emergency_contact_email: emergencyContactEmail,
      teams: selectedTeams
    }
    updatePlayer({ clubId, playerId, player })
  };

  useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardDidShow', () => {
      setIsKeyboardOpen(true);
    });
    const hideSubscription = Keyboard.addListener('keyboardDidHide', () => {
      setIsKeyboardOpen(false);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  useEffect(() => {
    if (isSuccess) {
      navigation.navigate('PlayersScreen');
    }
  }, [isSuccess]);

  return (
    <>
      <SizeClassView size={sizeClasses.regular}>
        <BarHeaderSmall
          title={'Update Player'}
          leftButtonTitle="Back"
          onLeftPress={() => {
            navigation.navigate('PlayersScreen', { updateTime: new Date().getTime() });
          }}
        />
        <AvoidingView>
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View style={{ flex: 1 }}>
              <VerticalScroll>
                <Centered>
                  <FormFieldText
                    label="First Name"
                    value={firstName}
                    onChangeText={setFirstName}
                    autoComplete={"off"}
                  />
                  <FormFieldText
                    label="Last Name"
                    value={lastName}
                    onChangeText={setLastName}
                    autoComplete={"off"}
                  />
                  <FormFieldText
                    label="Jumper Number"
                    value={jumperNumber}
                    onChangeText={setJumperNumber}
                  />
                  <FormFieldText
                    label="Emergency Contact First Name"
                    value={emergencyContactFirstName}
                    onChangeText={setEmergencyContactFirstName}
                    autoComplete={"off"}
                  />
                  <FormFieldText
                    label="Emergency Contact Last Name"
                    value={emergencyContactLastName}
                    onChangeText={setEmergencyContactLastName}
                    autoComplete={"off"}
                  />
                  <FormFieldText
                    label="Emergency Contact Mobile"
                    value={emergencyContactMobile}
                    onChangeText={setEmergencyContactMobile}
                    autoComplete={"off"}
                  />
                  <FormFieldText
                    label="Emergency Contact Email"
                    value={emergencyContactEmail}
                    onChangeText={setEmergencyContactEmail}
                    autoComplete={"off"}
                  />
                  <FormFieldTextMulti
                    label="Additional Information"
                    value={additionalInformation}
                    onChangeText={setAdditionalInformation}
                  />
                </Centered>
                <Spacer size="large" />
                <Spacer size="large" />
                <Spacer size="large" />
              </VerticalScroll>
            </View>
            <View style={{ flex: 1 }}>
              <VerticalScroll>
                <Centered>
                  {teamItems.length == 0 ? (
                    <>
                      <Spacer type="bottom" size="large" />
                      <Text style={{ textAlign: 'center' }}>No teams yet</Text>
                      <Spacer type="bottom" size="large" />
                    </>
                  ) : (
                    <FormTableSelect
                      label={'Assign teams'}
                      values={teamItems}
                      initialKeys={selectedTeams}
                      multiselect={true}
                      onSelected={setSelectedTeams}
                    />
                  )}
                </Centered>
                <Spacer size="large" />
                <Spacer size="large" />
                <Spacer size="large" />
              </VerticalScroll>
            </View>
          </View>
          <Footer>
            <Centered>
              <ButtonBlue
                label={saveLabel}
                isDisabled={!canSave || isPending}
                onPress={save}
              />
            </Centered>
            <SizeClassView size={sizeClasses.compact}>
              <Spacer type="bottom" size="large" />
            </SizeClassView>
          </Footer>
          <Loading isLoading={isPending} />
        </AvoidingView>
      </SizeClassView>
      <SizeClassView size={sizeClasses.compact}>
        <Background>
          <BarHeaderSmall
            title={'Update Player'}
            leftButtonTitle="Back"
            onLeftPress={() => {
              navigation.navigate('PlayersScreen', { updateTime: new Date().getTime() });
            }}
          />
          <AvoidingView>
            <VerticalScroll>
              <Centered>
                <FormFieldText
                  label="First Name"
                  value={firstName}
                  onChangeText={setFirstName}
                  autoComplete={"off"}
                />
                <FormFieldText
                  label="Last Name"
                  value={lastName}
                  onChangeText={setLastName}
                  autoComplete={"off"}
                />
                <FormFieldText
                  label="Jumper Number"
                  value={jumperNumber}
                  onChangeText={setJumperNumber}
                />
                <FormFieldText
                  label="Emergency Contact First Name"
                  value={emergencyContactFirstName}
                  onChangeText={setEmergencyContactFirstName}
                  autoComplete={"off"}
                />
                <FormFieldText
                  label="Emergency Contact Last Name"
                  value={emergencyContactLastName}
                  onChangeText={setEmergencyContactLastName}
                  autoComplete={"off"}
                />
                <FormFieldText
                  label="Emergency Contact Mobile"
                  value={emergencyContactMobile}
                  onChangeText={setEmergencyContactMobile}
                  autoComplete={"off"}
                />
                <FormFieldText
                  label="Emergency Contact Email"
                  value={emergencyContactEmail}
                  onChangeText={setEmergencyContactEmail}
                  autoComplete={"off"}
                />
                <FormFieldTextMulti
                  label="Additional Information"
                  value={additionalInformation}
                  onChangeText={setAdditionalInformation}
                />
                {teamItems.length == 0 ? (
                  <>
                    <Spacer type="bottom" size="large" />
                    <Text style={{ textAlign: 'center' }}>No teams yet</Text>
                    <Spacer type="bottom" size="large" />
                  </>
                ) : (
                  <FormTableSelect
                    label={'Assign teams'}
                    values={teamItems}
                    initialKeys={selectedTeams}
                    multiselect={true}
                    onSelected={setSelectedTeams}
                  />
                )}
              </Centered>
              <Spacer size="large" />
              <Spacer size="large" />
              <Spacer size="large" />
            </VerticalScroll>
            <Footer>
              <Centered>
                <ButtonBlue
                  label={saveLabel}
                  isDisabled={!canSave || isPending}
                  onPress={save}
                />
              </Centered>
              <SizeClassView size={sizeClasses.compact}>
                <Spacer type="bottom" size="large" />
              </SizeClassView>
            </Footer>
            <Loading isLoading={isPending} />
          </AvoidingView>
        </Background>
      </SizeClassView>
    </>
  );
};
