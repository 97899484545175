import camelize from 'camelize';
import {
  parseDate,
  parseDateTime,
  formattedDate,
  formattedShortDate,
} from 'utils/Dates';
import { apiSecureGet, apiSecureDelete, apiSecurePost, apiSecurePut } from 'api/ApiService';
import { formattedShortDateTime } from 'utils/Dates';

export const getClubUsers = async ({ queryKey }) => {
  const clubId = queryKey[1];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/users`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return usersTransform(data);
};

export const getClubUser = async ({ queryKey }) => {
  const clubId = queryKey[1];
  const userId = queryKey[3];
  const response = await apiSecureGet(`/v1/clubs/${clubId}/users/${userId}`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
  const data = await response.json();
  return userTransform(data);
};

export const createClubUser = async ({ clubId, club_user }) => {
  const response = await apiSecurePost(`/v1/clubs/${clubId}/users`, {
    club_user
  })
  if (!response.ok) {
    const json = await response.json();
    var errorMessages = '';
    if (json.errors) {
      for (const [key, value] of Object.entries(json.errors)) {
        errorMessages += `${key} ${value}\n`;
      }
    }
    if (!errorMessages) {
      errorMessages = 'Network response was not ok';
    }
    throw new Error(`${errorMessages}`);
  }
  const responseData = await response.json();
  return userTransform(responseData);
};

export const updateClubUser = async ({ clubId, clubUserId, club_user }) => {
  const response = await apiSecurePut(`/v1/clubs/${clubId}/users/${clubUserId}`, {
    club_user
  })
  if (!response.ok) {
    const json = await response.json();
    var errorMessages = '';
    if (json.errors) {
      for (const [key, value] of Object.entries(json.errors)) {
        errorMessages += `${key} ${value}\n`;
      }
    }
    if (!errorMessages) {
      errorMessages = 'Network response was not ok';
    }
    throw new Error(`${errorMessages}`);
  }
  const responseData = await response.json();
  return userTransform(responseData);
};

export const deleteClubUser = async ({ clubId, clubUserId }) => {
  const response = await apiSecureDelete(`/v1/clubs/${clubId}/users/${clubUserId}`)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }
};

const transform = (result = {}) => {
  if (result?.user) {
    result.user.createdAtShort = formattedShortDateTime(parseDateTime(result.user.created_at));
  }
  result.createdAtShort = formattedShortDateTime(parseDateTime(result.created_at));
  result.updatedAtShort = formattedShortDateTime(parseDateTime(result.updated_at));
  return camelize(result);
};

export const userTransform = (result = {}) => {
  return transform(result.data);
};

export const usersTransform = (results = {}) => {
  return results.data.map((result) => transform(result));
};
