import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import {
  DropDown,
  WebDropDown,
  WebDropDownItem
} from './styles';
import { colors } from '../../theme/colors';

export const DropDownListWeb = ({
  placeholder,
  items: initialItems,
  value: initialValue,
  onChangeValue,
  onOpen,
  onFocus,
  onBlur,
  isActive,
  enabled = true,
  multiple = false,
  style,
  containerStyle,
  listItemContainerStyle,
  dropDownContainerStyle,
  zIndex,
  zIndexInverse,
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (!isActive) setOpen(false);
  }, [isActive]);

  return (
    <WebDropDown
      style={{
        margin: 0,
        // borderWidth: 0.25,
        // borderRadius: 3,
        borderColor: colors.border,
        height: 34,
        ...style
      }}
      enabled={enabled}
      value={value}
      onFocus={onFocus}
      onBlur={onBlur}
      onValueChange={(_value, index) => {
        onChangeValue(items[index]?.id);
      }}>
      {items.map((item) => {
        return (
          <WebDropDownItem
            value={item.id}
            label={item.name}
            key={item.name}
          />
        )
      })}
    </WebDropDown>
  );
};
