import React, { useContext, useEffect, useMemo, useState } from 'react';

import { AvoidingView } from 'components/AvoidingView';
import { Background } from 'components/Background';
import { BarHeaderSmall } from 'components/BarHeaderSmall';
import { ButtonBlue } from 'components/ButtonBlue';
import { Centered } from 'components/Centered';
import { FormFieldTextDate } from 'components/FormFieldTextDate';
import { FormFieldSelect } from 'components/FormFieldSelect';
import { FormFieldTextMulti } from 'components/FormFieldTextMulti';
import { FormFieldToggle } from 'components/FormFieldToggle';
import { VerticalScroll } from 'components/VerticalScroll';
import { Spacer } from 'components/Spacer';

import { ProfileContext } from 'contexts/ProfileContext';
import { ReportTemplatesContext } from 'contexts/ReportTemplatesContext';
import { useGetLeagueFields } from 'hooks/useLeagueField';
import { useCreateReport, useGetReport, useUpdateReport } from 'hooks/useReport';
import { useGetSeasons } from 'hooks/useSeason';

export const CreateReportScreen = ({ navigation, route }) => {
  const { profile, currentClub } = useContext(ProfileContext);
  const { reportTemplates } = useContext(ReportTemplatesContext);
  const clubId = useMemo(() => currentClub?.clubId, [currentClub]);
  const leagueId = useMemo(() => currentClub?.club.affiliations[0]?.leagueId, [currentClub]);

  const [id, setId] = useState(null);
  const [reportId, setReportId] = useState(null);
  const [status, setStatus] = useState(null);
  const [player, setPlayer] = useState(null);
  const [team, setTeam] = useState(null);
  const [incidentAt, setIncidentAt] = useState('');
  const [activityType, setActivityType] = useState([]);
  const [presentationReason, setPresentationReason] = useState([]);
  const [protectiveEquipment, setProtectiveEquipment] = useState([]);
  const [referral, setReferral] = useState([]);
  const [drabcd, setDrabcd] = useState(false);
  const [concussion, setConcussion] = useState(false);
  const [injury, setInjury] = useState([]);
  const [injuryCause, setInjuryCause] = useState([]);
  const [treatment, setTreatment] = useState([]);
  const [adviceGiven, setAdviceGiven] = useState([]);
  const [treatmentAssisted, setTreatmentAssisted] = useState(false);
  const [oppositionTeam, setOppositionTeam] = useState([]);
  const [venue, setVenue] = useState('');
  const [comment, setComment] = useState('');
  const [currentPicker, setCurrentPicker] = useState(null);
  const [submittedBy, setSubmittedBy] = useState(null);

  const { mutate: createReport, isSuccess: hasCreated } = useCreateReport({ clubId });
  const { mutate: updateReport, isSuccess: hasUpdated } = useUpdateReport({ clubId, reportId });
  const { data: reportRecord } = useGetReport({ clubId, reportId })
  const { data: seasons } = useGetSeasons({ leagueId });
  const { data: leagueFields } = useGetLeagueFields({ leagueId });

  const fieldOptions = useMemo(() => {
    if (!leagueFields) return {};

    const stuff = leagueFields.data.map((field) => [field.slug, field.options])

    return Object.fromEntries(stuff);
  }, [leagueFields])

  const seasonName = useMemo(() => {
    if (incidentAt && seasons) {
      var incidentAtDate = Date.parse(incidentAt)
      var found = seasons.find((season) => {
        var startsOn = Date.parse(season.startsOn)
        var endsOn = Date.parse(season.endsOn)
        return incidentAtDate > startsOn && incidentAtDate < endsOn;
      })
      return found?.name ?? ''
    }
    return ''
  }, [incidentAt, seasons]);

  useEffect(() => {
    let today = new Date()
    setIncidentAt(today.toISOString().split('T')[0]);
  }, [])

  useEffect(() => {
    if (!!reportRecord) {
      setPlayer({ id: reportRecord.playerId, name: `${reportRecord.playerDetails?.firstName} ${reportRecord.playerDetails?.lastName}` });
      setTeam({ id: reportRecord.teamId, name: reportRecord.teamDetails?.teamName });
    }
  }, [reportRecord]);

  const reportTemplateId = useMemo(() => {
    if (reportTemplates.length > 0) {
      return reportTemplates[0].id
    }
  }, [reportTemplates]);

  const canSave = useMemo(() => {
    return (reportTemplateId) && (player?.id) && (injury.length > 0) &&
      (oppositionTeam.length > 0) && (!!venue);
  }, [reportTemplateId, player, injury, oppositionTeam, venue])

  const save = () => {
    const currentUserName = `${profile?.profile?.firstName} ${profile?.profile?.lastName}`;
    const formData = {
      id,
      player_id: player.id,
      team_id: team?.id,
      report_template_id: reportTemplateId,
      field_data: {
        player: player.name,
        incident_on: incidentAt,
        activityType: activityType,
        presentationReason: presentationReason,
        drabcd: drabcd ? 'Yes' : 'No',
        suspected_concussion: concussion ? 'Yes' : 'No',
        comment: comment,
        injuries: injury,
        injury_causes: injuryCause,
        protectiveEquipment: protectiveEquipment,
        treatments: treatment,
        treatments_assisted: treatmentAssisted ? 'Yes' : 'No',
        advices_given: adviceGiven,
        referral: referral,
        opposition_team: oppositionTeam,
        venue: venue,
        submitted_by: submittedBy ?? currentUserName,
      }
    };
    if (formData.id) {
      updateReport({ clubId, reportId, report: formData });
    } else {
      createReport({ clubId, report: formData });
    }
  };

  useEffect(() => {
    if (route.params?.report) {
      const { report } = route.params;
      setReportId(report.id);
      setId(report.id);
      setStatus(report.status);
      setActivityType(report.fieldData.activityType.filter(Boolean));
      setPresentationReason(report.fieldData.presentationReason.filter(Boolean));
      setProtectiveEquipment(report.fieldData.protectiveEquipment.filter(Boolean));
      setIncidentAt(report.fieldData.incidentOn);
      setPlayer({ id: report.playerId, name: report.fieldData.player });
      setTeam({ id: report.teamId, name: report.teamDetails?.teamName });
      setDrabcd(report.fieldData.drabcd === 'Yes');
      setConcussion(report.fieldData.suspectedConcussion === 'Yes');
      setInjury(report.fieldData.injuries.filter(Boolean));
      setInjuryCause(report.fieldData.injuryCauses.filter(Boolean));
      setTreatment(report.fieldData.treatments.filter(Boolean));
      setAdviceGiven(report.fieldData.advicesGiven.filter(Boolean));
      setReferral(report.fieldData.referral.filter(Boolean));
      setTreatmentAssisted(report.fieldData.treatmentsAssisted === 'Yes');
      setOppositionTeam(report.fieldData.oppositionTeam);
      setVenue(report.fieldData.venue);
      setComment(report.fieldData.comment);
      setSubmittedBy(report.fieldData?.submittedBy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params?.report]);

  useEffect(() => {
    if (route.params?.items) {
      switch (currentPicker) {
        case 'Team':
          setTeam(route.params?.items);
          break;
        case 'Player':
          const item = route.params?.items;
          setPlayer(item);
          var playerTeams = item.teamPlayers
            .sort((a, b) => (a.team?.name?.toLowerCase() < b.team?.name?.toLowerCase()) ? -1 : (a.team?.name?.toLowerCase() > b.team?.name?.toLowerCase()) ? 1 : 0);
          if (playerTeams.length > 0) {
            const team = playerTeams[0].team;
            setTeam(team);
          } else {
            setTeam(null);
          }
          break;
        case 'Injury':
          setInjury(route.params?.items);
          break;
        case 'ActivityType':
          setActivityType(route.params?.items);
          break;
        case 'PresentationReason':
          setPresentationReason(route.params?.items);
          break;
        case 'ProtectiveEquipment':
          setProtectiveEquipment(route.params?.items);
          break;
        case 'InjuryCause':
          setInjuryCause(route.params?.items);
          break;
        case 'Treatment':
          setTreatment(route.params?.items);
          break;
        case 'AdviceGiven':
          setAdviceGiven(route.params?.items);
          break;
        case 'Referral':
          setReferral(route.params?.items);
          break;
        case 'OppositionTeam':
          setOppositionTeam(route.params?.items);
          break;
        case 'Venue':
          setVenue(route.params?.items[0]);
          break;
      }
      setCurrentPicker(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params?.items]);


  useEffect(() => {
    if (hasCreated || hasUpdated) {
      navigation.navigate('ReportsScreen');
    }
  }, [hasCreated, hasUpdated]);

  return (
    <Background>
      <BarHeaderSmall
        title={id ? 'Update Injury Report' : 'Create Injury Report'}
        leftButtonTitle="Cancel"
        onLeftPress={() => {
          navigation.navigate('ReportsScreen', { updateTime: new Date().getTime() });
        }}
      />
      <AvoidingView>
        <VerticalScroll>
          <Centered>
            <FormFieldSelect
              label="Player"
              header="Player Name"
              required={true}
              values={[player?.name?.length > 0 ? player.name : 'Select']}
              onSelected={() => {
                setCurrentPicker('Player');
                navigation.navigate('PlayerSelectionScreen', {
                  title: 'Player',
                  header: 'PICK ONE',
                  selectedItems: player,
                });
              }}
            />
            <FormFieldSelect
              label="Team"
              header="Team Name"
              required={false}
              values={[team?.name?.length > 0 ? team?.name : 'No Team']}
              onSelected={() => {
                setCurrentPicker('Team');
                navigation.navigate('TeamSelectionScreen', {
                  title: 'Team',
                  header: 'PICK ONE',
                  player: player,
                  selectedItems: team,
                });
              }}
            />
            <FormFieldTextDate
              label="Incident Date"
              required={true}
              value={incidentAt}
              onBlur={(value) => {
                setIncidentAt(value);
              }}
            />
            <Spacer type="bottom" size="large" />
            <FormFieldSelect
              label="Type of activity"
              values={activityType.length > 0 ? activityType : ['Select']}
              onSelected={() => {
                setCurrentPicker('ActivityType');
                navigation.navigate('ListSelectionScreen', {
                  title: 'Type of activity',
                  header: 'PICK ONE',
                  items: fieldOptions['type-of-activity'],
                  multiselect: false,
                  selectedItems: activityType,
                });
              }}
            />
            <FormFieldSelect
              label="Reason for presentation"
              values={presentationReason.length > 0 ? presentationReason : ['Select']}
              onSelected={() => {
                setCurrentPicker('PresentationReason');
                navigation.navigate('ListSelectionScreen', {
                  title: 'Reason for presentation',
                  header: 'PICK ONE OR MORE',
                  items: fieldOptions['reason-for-presentation'],
                  multiselect: true,
                  selectedItems: presentationReason,
                });
              }}
            />
            <FormFieldToggle
              label="DRABCD"
              header="DANGER, RESPONSE, SEND FOR HELP, AIRWAY, BREATHING, COMPRESSION, DEFIBRILLATION"
              initialValue={drabcd}
              onSelected={setDrabcd}
            />
            <FormFieldToggle
              label="Suspected concussion"
              initialValue={concussion}
              onSelected={setConcussion}
            />
            <FormFieldSelect
              label="Injury"
              header="Injury"
              required={true}
              values={injury.length > 0 ? injury : ['Select']}
              onSelected={() => {
                setCurrentPicker('Injury');
                navigation.navigate('ListSelectionScreen', {
                  title: 'Injury',
                  header: 'PICK ONE OR MORE',
                  items: fieldOptions['injury'],
                  multiselect: true,
                  selectedItems: injury,
                });
              }}
            />
            <FormFieldSelect
              label="Cause of injury"
              values={injuryCause.length > 0 ? injuryCause : ['Select']}
              onSelected={() => {
                setCurrentPicker('InjuryCause');
                navigation.navigate('ListSelectionScreen', {
                  title: 'Cause of injury',
                  header: 'PICK ONE OR MORE',
                  items: fieldOptions['cause-of-injury'],
                  multiselect: true,
                  selectedItems: injuryCause,
                });
              }}
            />
            <FormFieldSelect
              label="Protective equipment"
              values={protectiveEquipment.length > 0 ? protectiveEquipment : ['Select']}
              onSelected={() => {
                setCurrentPicker('ProtectiveEquipment');
                navigation.navigate('ListSelectionScreen', {
                  title: 'Protective equipment',
                  header: 'PICK ONE OR MORE',
                  items: fieldOptions['protective-equipment'],
                  multiselect: true,
                  selectedItems: protectiveEquipment,
                });
              }}
            />
            <FormFieldSelect
              label="Treatment"
              values={treatment.length > 0 ? treatment : ['Select']}
              onSelected={() => {
                setCurrentPicker('Treatment');
                navigation.navigate('ListSelectionScreen', {
                  title: 'Treatment',
                  header: 'PICK ONE OR MORE',
                  items: fieldOptions['treatment'],
                  multiselect: true,
                  selectedItems: treatment,
                });
              }}
            />
            <FormFieldSelect
              label="Advice given"
              values={adviceGiven.length > 0 ? adviceGiven : ['Select']}
              onSelected={() => {
                setCurrentPicker('AdviceGiven');
                navigation.navigate('ListSelectionScreen', {
                  title: 'Advice given',
                  header: 'PICK ONE OR MORE',
                  items: fieldOptions['advice-given'],
                  multiselect: true,
                  selectedItems: adviceGiven,
                });
              }}
            />
            <FormFieldSelect
              label="Referral"
              values={referral.length > 0 ? referral : ['Select']}
              onSelected={() => {
                setCurrentPicker('Referral');
                navigation.navigate('ListSelectionScreen', {
                  title: 'Referral',
                  header: 'PICK ONE OR MORE',
                  items: fieldOptions['referral'],
                  multiselect: true,
                  selectedItems: referral,
                });
              }}
            />
            <FormFieldToggle
              label="Treated by Colbrow or other medic?"
              initialValue={treatmentAssisted}
              onSelected={setTreatmentAssisted}
            />
            <FormFieldSelect
              label="Opposition club"
              header="Opposition club"
              required={true}
              values={oppositionTeam.length > 0 ? oppositionTeam : ['Select']}
              onSelected={() => {
                setCurrentPicker('OppositionTeam');
                navigation.navigate('ListSelectionScreen', {
                  title: 'Opposition',
                  header: 'PICK ONE',
                  items: fieldOptions['opposition'],
                  multiselect: false,
                  selectedItems: oppositionTeam,
                });
              }}
            />
            <FormFieldSelect
              label="Venue"
              header="Venue"
              required={true}
              values={!!venue ? [venue] : ['Select']}
              onSelected={() => {
                setCurrentPicker('Venue');
                navigation.navigate('ListSelectionScreen', {
                  title: 'Venue',
                  header: 'PICK ONE',
                  items: fieldOptions['venue'],
                  multiselect: false,
                  allowSearch: true,
                  selectedItems: [venue],
                });
              }}
            />
            <FormFieldTextMulti
              label="Additional comments"
              value={comment}
              onChangeText={setComment}
            />
            <ButtonBlue
              label="Submit"
              isDisabled={!canSave}
              onPress={save}
            />
            <Spacer type="bottom" size="large" />
          </Centered>
        </VerticalScroll>
      </AvoidingView>
    </Background>
  );
};
