import React from 'react';

import { Background } from 'components/Background';
import { BarHeaderLarge } from 'components/BarHeaderLarge';
import { VerticalScroll } from 'components/VerticalScroll';

import { RoundSummaryJuniorClubs } from './RoundSummaryJuniorClubs';
import { SeasonSummaryJuniorClubs } from './SeasonSummaryJuniorClubs';
import { RoundSummarySeniorClubs } from './RoundSummarySeniorClubs';
import { SeasonSummarySeniorClubs } from './SeasonSummarySeniorClubs';
import { AllSeasonsOverviewJuniorClubs } from './AllSeasonsOverviewJuniorClubs';
import { ExportAllReportsData } from './ExportAllReportsData';
import { View } from 'react-native';

export const InsightsScreen = ({ navigation }) => {
  return (
    <Background>
      <BarHeaderLarge title="Insights" />
      <VerticalScroll>
        <View style={{zIndex: 100}}>
          <RoundSummaryJuniorClubs />
        </View>
        <View style={{zIndex: 90}}>
          <SeasonSummaryJuniorClubs />
          </View>
        <View style={{zIndex: 80}}>
          <RoundSummarySeniorClubs />
        </View>
        <View style={{zIndex: 70}}>
          <SeasonSummarySeniorClubs />
        </View>
        <View style={{zIndex: 60}}>
          <AllSeasonsOverviewJuniorClubs />
        </View>
        <View style={{zIndex: 50}}>
          <ExportAllReportsData />
        </View>
      </VerticalScroll>
    </Background>
  );
};
