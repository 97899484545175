import React from 'react';

import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';

import { ClubUsersScreen } from 'screens/ClubUsersScreen';
import { ClubUserAddScreen } from '../screens/ClubUserAddScreen';
import { ClubUserUpdateScreen } from '../screens/ClubUserUpdateScreen';

const ClubUsersStack = createStackNavigator();

const createScreenOptions = () => {
  return {
    headerShown: false,
    ...TransitionPresets.DefaultTransition,
  };
};

export const ClubUsersNavigator = () => {
  return (
    <ClubUsersStack.Navigator screenOptions={createScreenOptions}>
      <ClubUsersStack.Screen
        name="ClubUsersScreen"
        component={ClubUsersScreen}
        options={{ title: 'Users - Headfirst' }}
      />
      <ClubUsersStack.Screen
        name="ClubUserAddScreen"
        component={ClubUserAddScreen}
        options={{ title: 'Add a user - Headfirst' }}
      />
      <ClubUsersStack.Screen
        name="ClubUserUpdateScreen"
        component={ClubUserUpdateScreen}
        options={{ title: 'Update user - Headfirst' }}
      />
    </ClubUsersStack.Navigator>
  );
};
